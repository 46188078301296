// export const baseUrl = "https://stupidmen-backend.herokuapp.com";

// export const baseUrl = "http://192.168.0.192:8000/api/";
// export const baseUrl = "http://lionsdeneng-env.eba-dus3uewu.us-east-2.elasticbeanstalk.com/api/";

// Staging server backend
// export const baseUrl =
//   "http://lionsdengames-staging-env.eba-dus3uewu.us-east-2.elasticbeanstalk.com/api/";

// production server backend
export const baseUrl = "https://www.lionsprideengineering.com/api/";

export const apiPath = baseUrl;
