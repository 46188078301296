import http from "../../services/http";
import { handleErrors } from "../../helpers/error";
import { setEditUser, setUserState } from "../auth/eidtProfile";
import storage from "../../services/storage";
import { setUser } from "../auth/user";
import Toast from "../../Components/Toastify/Toastify";

export const getUser = ({ headers = {}, navigate, onSuccess, onError, onEnd }) => {
  return async (dispatch) => {
    try {
      
      const res = await http.get({
        url: "v1/partner/auth/splash",
        headers: { "x-auth-token": storage.get("xAuthToken") },
      });
      dispatch(setUser(res?.data?.user));
      
      dispatch(setEditUser(res?.data?.user));
      // console.log('splash api res of user', res?.data)
      dispatch(setUserState(res?.data?.states));
      onSuccess?.(res);
      if (
        res.data.success === false &&
        (res.data.message ===
          "Your Session has been expired, Kindly sign in to continue." ||
          res.data.message ===
            "We cant log you in. Kindly talk to customer support for further clarity."  ||
            res.data.message === "Sorry! Services not available in your country." ||
            res.data.message === "Sorry! Services not available in your region." ||
            res.data.message === "Access Denied. Must have PARTNER role." ||
            res.data.message === "You cannot use LionsDen with a VPN or Proxy.")
      ) {
        localStorage.clear();
        navigate("/");
        Toast.error(res.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    } catch (error) {
      if (onError) onError(error);
      else handleErrors(error);
    } finally {
      if (onEnd) onEnd();
    }
  };
};
export const UpdateUser = ({ body = {}, onSuccess, onError, onEnd }) => {
  return async (dispatch) => {
    try {
      const formData = new FormData();
      for (const key in body) {
        if (Object.hasOwnProperty.call(body, key)) {
          const value = body[key];
          formData.append(key, value);
        }
      }
      
      const res = await http.put({
        url: "v1/update_user_info",
        body: formData,
        headers: {
          "x-auth-token": storage.get("xAuthToken"),
        },
      });
      onSuccess?.(res);
  
      dispatch(setEditUser(res.data.data));
    } catch (error) {
      
      if (onError) onError(error);
      else handleErrors(error);
    } finally {
      if (onEnd) onEnd();
    }
  };
};
