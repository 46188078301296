import React, { useState } from "react";
import { InputGroup, FormControl } from "react-bootstrap";
import { Search } from "react-bootstrap-icons";

const SearchInput = ({ searchHandler }) => {
  const [inputValue, setInputValue] = useState("");

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      searchHandler(event.target.value);
      // setInputValue('')
    }
  };

  const searchClick = () => {
    // console.log("input value", inputValue);
    searchHandler(inputValue)
    // setInputValue('')
  };
  return (
    <InputGroup
      className=""
      style={{
        maxWidth: "250px",
        backgroundColor: "#33363E",
        borderRadius: "10px",
        marginRight: "5px",
        height: "45px",
      }}
    >
      <InputGroup.Text
        onClick={searchClick}
        style={{ backgroundColor: "#33363E", border: "none", color: "#fff", cursor: 'pointer' }}
      >
        <Search />
      </InputGroup.Text>
      <FormControl
        style={{ backgroundColor: "#33363E", color: "#fff", border: "none" }}
        className="custom-search-input"
        placeholder="Type here..."
        aria-label="Search"
        onKeyPress={handleKeyPress}
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
      />
    </InputGroup>
  );
};

export default SearchInput;
