import { createSlice } from "@reduxjs/toolkit";

const addReferrel = createSlice({
  name: "referralLink",
  initialState: {
    referralLinks: [], 
  },
  reducers: {
    addReferrelLink: (state, action) => {
      state.referralLinks.unshift({ ...action.payload, clicks: 0 }); 
    },
    getAllReferrelLinks: (state, action) => {
      const { referrals } = action.payload;
      state.referralLinks = referrals;
    },
  },
});

export const { addReferrelLink, getAllReferrelLinks } = addReferrel.actions;
export default addReferrel.reducer;
