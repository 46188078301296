import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Form } from "react-bootstrap";

const DateRangePicker = ({
  startDate,
  endDate,
  handleStartDateChange,
  handleEndDateChange,
  handleApplyDate,
}) => {
  return (
    <Form className="filterDateBox">
      <Form.Group>
        {/* <Form.Label>Start Date</Form.Label> */}
        <DatePicker
          maxDate={endDate || new Date()}
          selected={startDate}
          onChange={handleStartDateChange}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          placeholderText="Start date"
          className="form-control data-form-control"
        />
        {/* <DatePicker
          selectsRange
          endDate={endDate}
          maxDate={new Date()}
          selected={startDate}
          startDate={startDate}
          placeholderText="Select Date Range"
          onChange={(dateRange) => setDateRange(dateRange)}
        /> */}
      </Form.Group>
      <Form.Group>
        {/* <Form.Label>End Date</Form.Label> */}
        <DatePicker
          maxDate={new Date()}
          selected={endDate}
          onChange={handleEndDateChange}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          placeholderText="End date"
          className="form-control data-form-control"
        />
      </Form.Group>
      <div>
        <div className="btn_create">
          <button
            // eslint-disable-next-line no-undef
            disabled={startDate && endDate ? "" : true}
            variant="primary"
            onClick={handleApplyDate}
            className="date-themebtn-blue"
          >
            Apply
          </button>
        </div>
      </div>
    </Form>
  );
};

export default DateRangePicker;
