import { React } from "react";
import { SpinnerDotted } from "spinners-react";
import {
  formatNumber,
  TimestampComponent,
} from "../../../../services/number.services";
import { Link } from "react-router-dom";
import { AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai";
const AffiliateUsersTable = ({
  loading,
  usersData,
  currentPage,
  perPage,
  onClickColumn,
  isActive,
  order,
}) => {
  // console.log("usersData affiliate", usersData);
  return (
    <>
      <div>
        {/* <h1 className="f24 mb-0 text-white mt-64 ">Payout History</h1> */}

        <div className="table_width3 PayoutHistory-table mt-32">
          {/* <div className=""> */}
          {loading ? (
            <div className="table-loader">
              <SpinnerDotted style={{ height: "30", color: "white" }} />
            </div>
          ) : (
            <div className="custom_table table-wrapper">
              <table className="table giro-table table-bordered">
                <thead>
                  <tr>
                    <th className="br-r-8">#</th>
                    <th
                      className="pointer"
                      onClick={() => onClickColumn("username")}
                    >
                      Username{" "}
                      {isActive === "" ? (
                        ""
                      ) : isActive === "username" ? (
                        order === "asc" ? (
                          <AiOutlineArrowDown size="20px" color="#aab3d2" />
                        ) : (
                          <AiOutlineArrowUp size="20px" color="#aab3d2" />
                        )
                      ) : (
                        ""
                      )}
                    </th>
                    <th
                      className="pointer"
                      onClick={() => onClickColumn("status")}
                    >
                      Status{" "}
                      {isActive === "" ? (
                        ""
                      ) : isActive === "status" ? (
                        order === "asc" ? (
                          <AiOutlineArrowDown size="20px" color="#aab3d2" />
                        ) : (
                          <AiOutlineArrowUp size="20px" color="#aab3d2" />
                        )
                      ) : (
                        ""
                      )}
                    </th>
                    <th className="">Jumio Status</th>
                    <th className="">Total Purchase</th>
                    <th className="">Total Cashout</th>
                    <th
                      className="pointer"
                      onClick={() => onClickColumn("coins.purchasedWallet")}
                    >
                      Purchased Wallet{" "}
                      {isActive === "" ? (
                        ""
                      ) : isActive === "coins.purchasedWallet" ? (
                        order === "asc" ? (
                          <AiOutlineArrowDown size="20px" color="#aab3d2" />
                        ) : (
                          <AiOutlineArrowUp size="20px" color="#aab3d2" />
                        )
                      ) : (
                        ""
                      )}
                    </th>
                    <th
                      className="pointer"
                      onClick={() => onClickColumn("coins.winWallet")}
                    >
                      Winning Wallet{" "}
                      {isActive === "" ? (
                        ""
                      ) : isActive === "coins.winWallet" ? (
                        order === "asc" ? (
                          <AiOutlineArrowDown size="20px" color="#aab3d2" />
                        ) : (
                          <AiOutlineArrowUp size="20px" color="#aab3d2" />
                        )
                      ) : (
                        ""
                      )}
                    </th>
                    <th
                      className="pointer"
                      onClick={() => onClickColumn("coins?.stackingWallet")}
                    >
                      Stacking Wallet{" "}
                      {isActive === "" ? (
                        ""
                      ) : isActive === "coins?.stackingWallet" ? (
                        order === "asc" ? (
                          <AiOutlineArrowDown size="20px" color="#aab3d2" />
                        ) : (
                          <AiOutlineArrowUp size="20px" color="#aab3d2" />
                        )
                      ) : (
                        ""
                      )}
                    </th>
                    <th
                      className="pointer"
                      onClick={() => onClickColumn("createdAt")}
                    >
                      Date of Joining{" "}
                      {isActive === "" ? (
                        ""
                      ) : isActive === "createdAt" ? (
                        order === "asc" ? (
                          <AiOutlineArrowDown size="20px" color="#aab3d2" />
                        ) : (
                          <AiOutlineArrowUp size="20px" color="#aab3d2" />
                        )
                      ) : (
                        ""
                      )}
                    </th>
                    <th className="">Ledger</th>
                  </tr>
                </thead>
                <tbody className="scroll_bar  ">
                  {usersData?.length > 0 ? (
                    <>
                      {usersData?.map((item, key) => (
                        <tr key={key}>
                          <td className="br-r-8">
                            {(currentPage - 1) * perPage + key + 1}
                          </td>

                          <td className="">{item?.username}</td>
                          <td className="">{item?.status}</td>
                          <td>{item?.jumioStatus}</td>
                          <td>$ {formatNumber(item?.totalPurchases)}</td>
                          <td>$ {formatNumber(item?.totalCashouts)}</td>
                          <td>{formatNumber(item?.coins?.purchasedWallet)}</td>
                          <td>{formatNumber(item?.coins?.winWallet)}</td>
                          <td>{formatNumber(item?.coins?.stackingWallet)}</td>
                          <td>
                            <TimestampComponent
                              isoTimestamp={item?.createdAt}
                            />
                          </td>
                          <td>
                            <Link
                              className="text-decoration-none"
                              to={`/affiliate-users/ledger/${item?.username}/${item?._id}`}
                            >
                              <button className="themeButtonLedger">
                                Ledger
                              </button>
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </>
                  ) : (
                    <>
                      <h1
                        className="clr-white"
                        style={{ fontSize: "28px", color: "white" }}
                      >
                        No Referral Users available
                      </h1>
                    </>
                  )}
                </tbody>
              </table>
            </div>
          )}

          {/* </div> */}
        </div>
      </div>
    </>
  );
};

export default AffiliateUsersTable;
