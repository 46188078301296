import React, { useState } from "react";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { SpinnerDotted } from "spinners-react";
import Toast from "../../Components/Toastify/Toastify";
import { login } from "../../store/api/auth";
import { setCredational } from "../../store/ui/loginUi";
import logo from "./../../assets/HomeAssets/assets/images/logo.png";
import eyes from "./../../assets/HomeAssets/assets/images/eyes.png";
import eyes2 from "./../../assets/HomeAssets/assets/images/eyes2.png";
import Header from "../../Components/Layout/Header/Header";
import { Helmet } from "react-helmet";

export default function LoginPage() {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const dispatch = useDispatch();
  const onSubmit = async (values) => {
    setLoading(true);

    dispatch(setCredational(values));
    dispatch(
      login({
        body: {
          email: values.email,
          password: values.password,
        },
        onSuccess: (res) => {
          setLoading(false);
          if (res.data.success) {
            if (res.data.user.onBoarded) {
              navigate("/dashboard");
            } else {
              navigate("/on-boarding");
            }
            Toast.success(res.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
          } else
            Toast.error(res.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
        },
        onError: (error) => {
          setLoading(false);
          if (error.response) {
            if (error.response.data.errors) {
              for (let key in error.response.data.errors) {
                setError(error.response.data.errors[key]);
                Toast.error(error.response.data.errors[key], {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "dark",
                });
              }
            } else if (error.response.data.message) {
              setError(error.response.data.message);
              Toast.error(error.response.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
            }
          }
        },
      })
    );
  };
  const initialValues = {
    email: "",
    password: "",
  };
  const validationSchema = Yup.object({
    email: Yup.string().email("Invalid email format").required("Required"),
    password: Yup.string().required("Required"),
  });
  const formdata = () => {
    return (
      <>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            errors,
            touched,
          }) => (
            <>
              <div className="login-form ">
                <div className="login-logo mb-32 mx-auto text-center">
                  <img src={logo} alt="logo" />
                </div>
                <div className="login-field input-box">
                  <h1 className="f36 text-center mb-32">Partner Login</h1>

                  <div className="input-box-field form-floating">
                    <input
                      type="email"
                      className="form-control login-form-control"
                      id="login-email"
                      onChange={handleChange("email")}
                      onBlur={handleBlur("email")}
                      value={values.email}
                      placeholder="email"
                      autoComplete="off"
                    />
                    <label htmlFor="login-email">Email</label>

                    {errors.email && touched.email ? (
                      <div style={{ color: "red" }}>
                        <small>{errors.email}</small>
                      </div>
                    ) : null}
                  </div>
                  <div className="input-box-field ">
                    <div className="icon-wrap form-floating">
                      <input
                        // type="password"
                        type={showPassword ? "text" : "password"}
                        className="form-control login-form-control"
                        id="login-password"
                        placeholder="Password"
                        onChange={handleChange("password")}
                        onBlur={handleBlur("password")}
                        value={values.password}
                      />
                      <img
                        src={showPassword ? eyes2 : eyes}
                        className="showeye"
                        onClick={togglePasswordVisibility}
                      />
                      <label htmlFor="login-password"> Password </label>
                    </div>
                    {errors.password && touched.password ? (
                      <div style={{ color: "red" }}>
                        <small>{errors.password}</small>
                      </div>
                    ) : null}
                  </div>
                  <div className="login-bottom mt-24">
                    <label className="redio_wrap">
                      <input type="checkbox" name="radio" id="Remember-me" />
                      <label htmlFor="Remember-me">Remember me</label>
                    </label>
                    <h1
                      className="f16 mb-0 "
                      onClick={() => navigate("/forgot-password")}
                    >
                      Forgot password
                    </h1>
                  </div>
                  <button
                    type="button"
                    className="themebtn-blue w-100 mt-32"
                    onClick={handleSubmit}
                    disabled={loading ? true : false}
                  >
                    {loading ? (
                      <SpinnerDotted
                        style={{ height: "40", color: "#011343" }}
                      />
                    ) : (
                      "Sign In"
                    )}
                  </button>
                </div>
              </div>
            </>
          )}
        </Formik>
      </>
    );
  };
  return (
    <>
      <Helmet>
        <title>Lionsden Partner Portal - Login</title>
        <meta
          name="description"
          content="LionsDen Games - Unleash Your Inner Lion - The Ultimate Sweepstakes Casino Experience in America. Experience the thrill, embrace the excitement, and ignite your passion at LionsDen Games – your ultimate online adventure!"
        />
      </Helmet>
      <div className="bg_wrap scroll-container2">
        <Header />
        <div className="formBox_main">
          <div className="formBox">
            <div className="login-body">
              <div className="wrap-login-body mt2">{formdata()}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
