import React, { useState } from "react";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { SpinnerDotted } from "spinners-react";
import { forgotPassword } from "../../store/api/auth";
import Toast from "../../Components/Toastify/Toastify";
import back from "./../../assets/HomeAssets/assets/images/back.png";
import logo from "./../../assets/HomeAssets/assets/images/logo.png";
import Header from "../../Components/Layout/Header/Header";
import { Helmet } from "react-helmet";

export default function ResetEmail() {
  const [error, setError] = useState("");
  let email = localStorage.getItem("email");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClick = () => {
    window.open("https://mail.google.com/mail/u/0/#inbox");
  };

  const onSubmit = () => {
    if (loading) return;

    setLoading(true);

    dispatch(
      forgotPassword({
        body: {
          email: email,
        },
        onSuccess: (res) => {
          setLoading(false);
          if (res.data.success) {
            Toast.success(res.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
          } else {
            Toast.error(res.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
          }
        },

        onError: (error) => {
          setLoading(false);
          if (error.response) {
            if (error.response.data.errors) {
              for (let key in error.response.data.errors) {
                setError(error.response.data.errors[key]);
              }
            } else if (error.response.data) {
              setError(error.response.data);
            }
          }
        },
      })
    );
  };

  const initialValues = {
    email: "",
  };
  const validationSchema = Yup.object({
    email: Yup.string().email("Invalid email format").required("Required"),
  });

  const formdata = () => {
    return (
      <>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            errors,
            touched,
          }) => (
            <>
              <div className="login-form">
                <div className="login-logo mb-32 mx-auto text-center">
                  <img src={logo} alt="logo" />
                </div>
                <div className="login-field">
                  <h1 className="f36 text-center">Check your email</h1>
                  <h1 className="f16b text-center mt-17">
                    We sent a password reset link to {email}{" "}
                  </h1>
                  <button
                    className="themebtn-blue w-100 mt-64 mt-resend"
                    onClick={handleClick}
                  >
                    Open email app
                  </button>
                  <p className="f16 mt-17 txt_flex mb-0">
                    Didn't receive the email?
                    <span
                      className="ml-2  txt-blue"
                      onClick={onSubmit}
                      disabled={loading ? true : false}
                    >
                      <u>
                        {loading ? (
                          <SpinnerDotted
                            style={{ height: "40", color: "#011343" }}
                          />
                        ) : (
                          "Click to resend"
                        )}
                      </u>
                    </span>
                  </p>
                  <div className="back back_btn mt-32 d-flex align-items-center">
                    <img
                      src={back}
                      alt="back button"
                      onClick={() => navigate("/")}
                    />
                    <h1
                      className="f16 mb-0 hover"
                      onClick={() => navigate("/")}
                    >
                      Back to log In
                    </h1>
                  </div>
                </div>
              </div>
            </>
          )}
        </Formik>
      </>
    );
  };
  return (
    <>
    <Helmet>
        <title>Lionsden Partner Portal - Reset Email</title>
        <meta
          name="description"
          content="LionsDen Games - Unleash Your Inner Lion - The Ultimate Sweepstakes Casino Experience in America. Experience the thrill, embrace the excitement, and ignite your passion at LionsDen Games – your ultimate online adventure!"
        />
      </Helmet>
      <div className="bg_wrap scroll-container2">
        <Header />
        <div className="formBox_main">
          <div className="formBox h-auto">
            <div className="wrap-login-body mt2"> {formdata()}</div>
          </div>
        </div>
      </div>
    </>
  );
}
