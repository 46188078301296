import { React } from "react";

import { SpinnerDotted } from "spinners-react";
import { TimestampComponent } from "../../../../services/number.services";

const JKDigitalsTransactionTable = ({
  loading,
  usersData,
  currentPage,
  perPage,
}) => {
  return (
    <>
      <div>
        {/* <h1 className="f24 mb-0 text-white mt-64 ">Payout History</h1> */}

        <div className="table_width3 PayoutHistory-table mt-32">
          <div className="table_width3">
            {loading ? (
              <div className="table-loader">
                <SpinnerDotted style={{ height: "30", color: "white" }} />
              </div>
            ) : (
              <div className="custom_table table-wrapper">
                <table className="table giro-table table-bordered">
                  <thead>
                    <tr>
                      <th className="br-r-8">#</th>
                      <th className="align-center">Username</th>
                      <th className="align-center">Status</th>
                      {/* <th className="align-center">Flag</th> */}
                      <th className="align-center">Amount</th>
                      <th className="align-center">Type</th>
                      <th className="align-center">Reason</th>
                      <th className="align-center">Commission</th>
                      <th className="align-center">Lionsden ID</th>
                      <th className="align-center">Time</th>
                      <th className="align-center">City/State</th>
                    </tr>
                  </thead>
                  <tbody className="scroll_bar">
                    {usersData?.length > 0 ? (
                      <>
                        {usersData?.map((item, key) => (
                          <tr key={key}>
                            <td className="br-r-8 align-center">
                              {(currentPage - 1) * perPage + key + 1}
                            </td>
                            <td className="align-center">
                              {item?.userId.username}
                            </td>
                            <td className="align-center">{item?.status}</td>
                            <td className="align-center">$ {item?.amount}</td>
                            <td className="align-center">{item?.type}</td>
                            <td className="align-center">
                              {!item?.Reason || item?.Reason === ""
                                ? "---"
                                : item?.Reason}
                            </td>
                            <td className="align-center">
                              ${" "}
                              {item?.comission?.amount
                                ? item?.comission?.amount
                                : 0}
                            </td>
                            <td className="align-center">{item?._id ? item?._id : "---"}</td>
                            <td>
                              {" "}
                              <TimestampComponent
                                isoTimestamp={item?.createdAt}
                              />
                            </td>
                            <td className="align-center">
                            {item.ipinfo ? `${item.ipinfo.city} / ${item.ipinfo.region}`  : "---"}
                            </td>
                          </tr>
                        ))}
                      </>
                    ) : (
                      <>
                        <h1 className="clr-white" style={{ fontSize: "28px", color: 'white' }}>
                          No Transactions available
                        </h1>
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default JKDigitalsTransactionTable;
