import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { SpinnerDotted } from "spinners-react";
import Header from "../../Components/Layout/Header/Header";
import { onBoard } from "../../store/api/auth";
import Toast from "../../Components/Toastify/Toastify";
import validateForm from "./Validation";
import { Helmet } from "react-helmet";
import { getUser } from "../../store/api/userEdit";
const Onboarding = () => {
  const [stepCount, setStepCount] = useState(1);
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [parthner, setParthner] = useState({
    userName: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    street: "",
    city: "",
    state: "",
    zipCode: "",
  });

  const handleValue = (event) => {
    const { name, value } = event.target;
    let val = value;
    if (name === "phoneNumber") {
      val = val.replace(/\D/g, "");
      val = val ? "+" + val.replaceAll("+", "") : "";
    }
    setParthner((prev) => ({
      ...prev,
      [name]: val,
    }));
    setError((prev) => ({ ...prev, [name]: "" }));
  };
  const handleKeyPress = (e) => {
    if (e.key !== "Backspace" && isNaN(Number(e.key)))
      return e.preventDefault();
  };
  const handleNextBtn = () => {
    if (
      error.userName ||
      error.firstName ||
      error.lastName ||
      error.phoneNumber
    )
      return;
    // if (Object.values(error).filter((el) => !!el).length !== 0)

    const validationCheck = validateForm(stepCount, parthner);
    if (Object.keys(validationCheck).length === 0) {
      setStepCount(stepCount + 1);
    }
    setError(validationCheck);
  };
  const handleBack = () => {
    setStepCount((prev) => prev - 1);
  };

  const handleLogOut = () => {
    localStorage.clear();
    navigate("/");
  };

  const onSubmit = () => {
    const validationCheck = validateForm(stepCount, parthner);

    if (Object.keys(validationCheck).length === 0) {
      setLoading(true);
      dispatch(
        onBoard({
          body: {
            username: parthner.userName.trim(),
            firstname: parthner.firstName.trim(),
            lastname: parthner.lastName.trim(),
            phone: `${parthner.phoneNumber}`,
            address: {
              street: parthner.street.trim(),
              city: parthner.city.trim(),
              state: parthner.state.trim(),
              zipCode: `${parthner.zipCode}`,
            },
          },
          onSuccess: (res) => {
            setLoading(false);

            const { success, message } = res.data;

            if (success) {
              navigate("/dashboard");
              Toast.success(message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
              return;
            }

            if (
              message ===
                "Your Session has been expired, Kindly sign in to continue." ||
              message ===
                "We cant log you in. Kindly talk to customer support for further clarity."
            ) {
              localStorage.clear();
              navigate("/");
              Toast.error(message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
            } else if (
              message === "This username already exist in our system" ||
              message ===
                "The phone number is already registered. Please provide a different number."
            ) {
              setStepCount(1);

              if (message === "This username already exist in our system")
                setError({
                  userName: "This username already exist in our system",
                });
              else
                setError({
                  phoneNumber:
                    "The phone number is already registered. Please provide a different number.",
                });
            } else {
              Toast.error(message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
            }
          },
          onError: (error) => {
            setLoading(false);
            if (error.response) {
              if (error.response.data.errors) {
                for (let key in error.response.data.errors) {
                  setError(error.response.data.errors[key]);
                  Toast.error(error.response.data.errors[key], {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                  });
                }
              } else if (error.response.data.message) {
                setError(error.response.data.message);
                Toast.error(error.response.data.message, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "dark",
                });
              }
            }
          },
        })
      );
    }
    setError(validationCheck);
  };
  useEffect(() => {
    dispatch(getUser({ navigate }));
  }, []);

  return (
    <>
      <Helmet>
        <title>Lionsden Partner Portal - On-boarding</title>
        <meta
          name="description"
          content="LionsDen Games - Unleash Your Inner Lion - The Ultimate Sweepstakes Casino Experience in America. Experience the thrill, embrace the excitement, and ignite your passion at LionsDen Games – your ultimate online adventure!"
        />
      </Helmet>
      <div className="onboarding-wrap bg_dark scroll-container2">
        <Header />
        <div className="formBox_main">
          <div className="Onboarding_box">
            <h1 className="f36 text-white let-get">
              Let's get your Partner Account Setup
            </h1>
            <div className="OnboardingForm">
              <div className="OnboardingForm">
                <div className="formStep w280">
                  <ul>
                    <li
                      className={`${
                        stepCount === 1 || stepCount === 2 ? "active" : ""
                      }`}
                    >
                      <span>Basic info</span>
                    </li>
                    <li className={`${stepCount === 2 ? "active" : ""}`}>
                      <span>Address</span>
                    </li>
                    <li className="txt-blue">Join the platform</li>
                  </ul>
                </div>
                {stepCount === 1 && (
                  <>
                    <div className="form-box w368">
                      <h1 className="f24 mb-24">Basic info</h1>
                      <div className="form_box input-box">
                        <div className="input-box-field form-floating">
                          <input
                            type="text"
                            className="form-control login-form-control"
                            id="Username"
                            placeholder="Username"
                            autoComplete="off"
                            name="userName"
                            onChange={handleValue}
                            value={parthner.userName}
                          />
                          <label htmlFor="Username">Username</label>

                          {error.userName && (
                            <div
                              style={{
                                textAlign: "right",
                                color: "#FF0044",
                              }}
                            >
                              <small
                                style={{
                                  fontSize: "14px",
                                }}
                              >
                                {error.userName}
                              </small>
                            </div>
                          )}
                        </div>
                        <div className="input-box-field form-floating">
                          <input
                            type="text"
                            className="form-control login-form-control"
                            id="Firstname"
                            placeholder="First name"
                            autoComplete="off"
                            name="firstName"
                            onChange={handleValue}
                            value={parthner.firstName}
                          />
                          <label htmlFor="Firstname">First name</label>
                          {error.firstName && (
                            <div
                              style={{
                                textAlign: "right",
                                color: "#FF0044",
                              }}
                            >
                              <small
                                style={{
                                  fontSize: "14px",
                                }}
                              >
                                {error.firstName}
                              </small>
                            </div>
                          )}
                        </div>
                        <div className="input-box-field form-floating">
                          <input
                            type="text"
                            className="form-control login-form-control"
                            id="Lastname"
                            placeholder="Last name"
                            autoComplete="off"
                            name="lastName"
                            onChange={handleValue}
                            value={parthner.lastName}
                          />
                          <label htmlFor="Lastname">Last name</label>
                          {error.lastName && (
                            <div
                              style={{
                                textAlign: "right",
                                color: "#FF0044",
                              }}
                            >
                              <small
                                style={{
                                  fontSize: "14px",
                                }}
                              >
                                {error.lastName}
                              </small>
                            </div>
                          )}
                        </div>
                        <div className="input-box-field form-floating">
                          <input
                            type="text"
                            className="form-control login-form-control"
                            id="Phonenumber"
                            placeholder="Phone number"
                            autoComplete="off"
                            name="phoneNumber"
                            onKeyDown={handleKeyPress}
                            onChange={handleValue}
                            value={parthner.phoneNumber}
                          />
                          <label htmlFor="Phonenumber">Phone number</label>
                          {error.phoneNumber && (
                            <div
                              style={{
                                textAlign: "right",
                                color: "#FF0044",
                              }}
                            >
                              <small
                                style={{
                                  fontSize: "14px",
                                }}
                              >
                                {error.phoneNumber}
                              </small>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="form-btn d-flex mt-64">
                        <button
                          type="button"
                          className="themebtn-dark w-100  mr-16"
                          onClick={handleBack}
                          disabled={stepCount === 1 ? true : false}
                        >
                          Back
                        </button>
                        <button
                          type="button"
                          className="themebtn-blue w-100"
                          onClick={handleNextBtn}
                        >
                          Next
                        </button>
                      </div>
                      <div className="mt-32 form-btn d-flex">
                        <button
                          type="button"
                          className="themebtn-dark w-100"
                          onClick={handleLogOut}
                        >
                          Log out
                        </button>
                      </div>
                    </div>
                  </>
                )}
                {stepCount === 2 && (
                  <>
                    <div className="form-box w368">
                      <h1 className="f24 mb-24">Address</h1>
                      <div className="form_box input-box">
                        <div className="input-box-field form-floating">
                          <input
                            type="text"
                            className="form-control login-form-control"
                            id="Username"
                            placeholder="Street"
                            autoComplete="off"
                            name="street"
                            onChange={handleValue}
                            value={parthner.street}
                          />
                          <label htmlFor="street">Street</label>

                          {error.street && (
                            <div
                              style={{
                                textAlign: "right",
                                color: "#FF0044",
                              }}
                            >
                              <small
                                style={{
                                  fontSize: "14px",
                                }}
                              >
                                {error.street}
                              </small>
                            </div>
                          )}
                        </div>
                        <div className="input-box-field form-floating">
                          <input
                            type="text"
                            className="form-control login-form-control"
                            id="Firstname"
                            placeholder="City"
                            autoComplete="off"
                            name="city"
                            onChange={handleValue}
                            value={parthner.city}
                          />
                          <label htmlFor="city">City</label>
                          {error.city && (
                            <div
                              style={{
                                textAlign: "right",
                                color: "#FF0044",
                              }}
                            >
                              <small
                                style={{
                                  fontSize: "14px",
                                }}
                              >
                                {error.city}
                              </small>
                            </div>
                          )}
                        </div>
                        <div className="input-box-field form-floating">
                          <input
                            type="text"
                            className="form-control login-form-control"
                            id="Lastname"
                            placeholder="State"
                            autoComplete="off"
                            name="state"
                            onChange={handleValue}
                            value={parthner.state}
                          />
                          <label htmlFor="state">State</label>
                          {error.state && (
                            <div
                              style={{
                                textAlign: "right",
                                color: "#FF0044",
                              }}
                            >
                              <small
                                style={{
                                  fontSize: "14px",
                                }}
                              >
                                {error.state}
                              </small>
                            </div>
                          )}
                        </div>
                        <div className="input-box-field form-floating">
                          <input
                            type="number"
                            className="form-control login-form-control"
                            id="Phonenumber"
                            placeholder="ZipCode"
                            autoComplete="off"
                            name="zipCode"
                            onChange={handleValue}
                            value={parthner.zipCode}
                          />
                          <label htmlFor="zipCode">Zip code</label>
                          {error.zipCode && (
                            <div
                              style={{
                                textAlign: "right",
                                color: "#FF0044",
                              }}
                            >
                              <small
                                style={{
                                  fontSize: "14px",
                                }}
                              >
                                {error.zipCode}
                              </small>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="form-btn d-flex mt-64">
                        <button
                          type="button"
                          className="themebtn-dark w-100  mr-16"
                          onClick={handleBack}
                        >
                          Back
                        </button>
                        <button
                          type="button"
                          className="themebtn-blue w-100"
                          // onClick={handleNextBtn}
                          disabled={loading ? true : false}
                          onClick={onSubmit}
                        >
                          {loading ? (
                            <SpinnerDotted
                              style={{ height: "40", color: "#73C4FF" }}
                            />
                          ) : (
                            "Sign me up"
                          )}
                        </button>
                      </div>
                      <div className="mt-32 form-btn d-flex">
                        <button
                          type="button"
                          className="themebtn-dark w-100"
                          onClick={handleLogOut}
                        >
                          Log out
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Onboarding;
