import React, { useState } from "react";
import logo from "../../../assets/HomeAssets/assets/images/logo.png";
import dashboard from "../../../assets/HomeAssets/assets/images/SideBar/Dashboard.png";
import setting from "../../../assets/HomeAssets/assets/images/SideBar/setting.png";
import gethelp from "../../../assets/HomeAssets/assets/images/SideBar/gethelp.png";
import Billing from "../../../assets/HomeAssets/assets/images/SideBar/Billing.png";
import Cashouts from "../../../assets/HomeAssets/assets/images/icon/Banknote.png";
import Logout from "../../../assets/HomeAssets/assets/images/SideBar/Logout.png";
import Profits from "../../../assets/HomeAssets/assets/images/SideBar/expand.png";
import Users from "../../../assets/HomeAssets/assets/images/SideBar/account.png";
import { Link, Route, useLocation } from "react-router-dom";
import storage from "../../../services/storage";

const SideBar = () => {
  const location = useLocation();
  const [activeLink, setActiveLink] = useState(location.pathname);
  console.log("location.pathname", location.pathname);
  const user = storage.get("user");
  const handleLinkClick = (to) => {
    localStorage.clear();
    setActiveLink(to);
  };
  return (
    <>
      <nav id="sidebar " className="scroll-container2">
        <div className="SideBar_inner">
          <div className="siderBar-header text-center">
            <img src={logo} alt="logo" />
          </div>
          <div className="SideBar-list">
            <ul>
              <li>
                <Link
                  to="/dashboard"
                  className={activeLink === "/dashboard" ? "active" : ""}
                >
                  <img src={dashboard} alt="dashboard" /> Dashboard
                </Link>
              </li>

              <li>
                <Link
                  to="/affiliate-users"
                  className={
                    activeLink === "/affiliate-users" ||
                    location.pathname.startsWith("/affiliate-users/")
                      ? "active"
                      : ""
                  }
                >
                  <img src={Profits} alt="Users" /> Referral Users
                </Link>
              </li>
              <li>
                <Link
                  to="/un-approved-users"
                  className={
                    activeLink === "/un-approved-users" ? "active" : ""
                  }
                >
                  <img src={Users} alt="Users" /> Unapproved Users
                </Link>
              </li>
              <li>
                <Link
                  to="/purchase-history"
                  className={activeLink === "/purchase-history" ? "active" : ""}
                >
                  <img src={Billing} alt="Billing" /> Purchase History
                </Link>
              </li>
              <li>
                <Link
                  to="/referral-cashouts"
                  className={activeLink === "/referral-cashouts" ? "active" : ""}
                >
                  <img src={Billing} alt="Billing" /> Cashout History
                </Link>
              </li>
              {user?.role === "PARENT_PARTNER" && (
                <li>
                  <Link
                    to="/affiliate-profits"
                    className={
                      activeLink === "/affiliate-profits" ? "active" : ""
                    }
                  >
                    <img src={Cashouts} alt="Users" /> Affiliate Profits
                  </Link>
                </li>
              )}
               <li>
                  <Link
                    to="/referral-profits"
                    className={
                      activeLink === "/referral-profits" ? "active" : ""
                    }
                  >
                    <img src={Cashouts} alt="Users" /> Referral Profits
                  </Link>
                </li>
              <li>
                <Link
                  to="/settings"
                  className={activeLink === "/settings" ? "active" : ""}
                >
                  <img src={setting} alt="setting" /> Settings
                </Link>
              </li>
              <li>
                <Link
                  to="/gethelp"
                  className={activeLink === "/gethelp" ? "active" : ""}
                >
                  <img src={gethelp} alt="gethelp" /> Get help
                </Link>
              </li>
              <li>
                <Link
                  to="/billing"
                  className={activeLink === "/billing" ? "active" : ""}
                >
                  <img src={Billing} alt="Billing" /> Billing
                </Link>
              </li>
              <li>
                <Link
                  to="/"
                  className={activeLink === "/" ? "active" : ""}
                  onClick={() => handleLinkClick("/")}
                >
                  <img src={Logout} alt="Logout" /> Logout
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default SideBar;
