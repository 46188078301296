import { setUser } from "../auth/user";
import http from "../../services/http";
import storage from "../../services/storage";
import { showLoginLoader } from "../ui/loginUi";
import { handleErrors } from "../../helpers/error";

const basicinfo = "v1/partner/auth/update-profile";

export const newPassword = ({
  id = "",
  onSuccess,
  onError,
  onEnd,
  body = {},
} = {}) => {
  return async (dispatch, getState) => {
    try {
      const res = await http.post({
        url: "v1/partner/auth/reset-password" + id,
        body,
      });

      //onSuccess event firing
      if (onSuccess) onSuccess(res);
    } catch (err) {
      //onError event firing
      if (onError) onError(err);
      else handleErrors(err);
    } finally {
      if (onEnd) onEnd();
    }
  };
};

export const onBoard = ({ body = {}, onSuccess, onError, onEnd }) => {
  return async (dispatch) => {
    try {
      //showing loader on ui
      const res = await http.patch({
        body,
        url: "v1/partner/auth/onboard",
        headers: { "x-auth-token": storage.get("xAuthToken") },
      });
      const { data } = res;
      let user = data.user;

      storage.store("user", user);

      onSuccess(res);
    } catch (error) {
      if (onError) onError(error);
      else handleErrors(error);
    } finally {
      if (onEnd) onEnd();
    }
  };
};

export const forgotPassword = ({
  id = "",
  onSuccess,
  onError,
  onEnd,
  body = {},
} = {}) => {
  return async (dispatch, getState) => {
    try {
      const res = await http.post({
        url: "v1/partner/auth/forgot-password",
        body,
      });

      //onSuccess event firing
      if (onSuccess) onSuccess(res);
    } catch (err) {
      //onError event firing
      if (onError) onError(err);
      else handleErrors(err);
    } finally {
      if (onEnd) onEnd();
    }
  };
};

export const login = ({ body, onSuccess, onError }) => {
  return async (dispatch, getState) => {
    try {
      dispatch(showLoginLoader(true));

      const res = await http.post({
        url: "v1/partner/auth/signin",
        body,
      });

      const { data } = res;
      const { token } = data;
      let user = data?.user;
      if (res.data.success) {
        dispatch(setUser(user));
        storage.store("xAuthToken", token);
      }

      onSuccess(res);
      dispatch(showLoginLoader(false));
    } catch (error) {
      dispatch(showLoginLoader(false));
      if (onError) onError(error);
    }
  };
};

export const updateBasic = ({ body = {}, onSuccess, onError, onEnd }) => {
  return async (dispatch) => {
    try {
      //showing loader on ui
      const res = await http.patch({
        body,
        url: basicinfo,
        headers: { "x-auth-token": storage.get("xAuthToken") },
      });
      // dispatch(setUser(res?.data?.user));
      onSuccess(res);
    } catch (error) {
      if (onError) onError(error);
      else handleErrors(error);
    } finally {
      if (onEnd) onEnd();
    }
  };
};

export const updateAddess = ({ body = {}, onSuccess, onError, onEnd }) => {
  return async (dispatch) => {
    try {
      //showing loader on ui
      const res = await http.patch({
        body,
        url: "v1/partner/auth/update-address",
        headers: { "x-auth-token": storage.get("xAuthToken") },
      });
      // dispatch(setUser(res?.data?.user));
      onSuccess(res);
    } catch (error) {
      if (onError) onError(error);
      else handleErrors(error);
    } finally {
      if (onEnd) onEnd();
    }
  };
};

export const changePassword = ({ body = {}, onSuccess, onError, onEnd }) => {
  return async (dispatch) => {
    try {
      //showing loader on ui
      const res = await http.put({
        body,
        url: "v1/partner/auth/change-password",
        headers: { "x-auth-token": storage.get("xAuthToken") },
      });
      // dispatch(setUser(res?.data?.user));
      onSuccess(res);
    } catch (error) {
      if (onError) onError(error);
      else handleErrors(error);
    } finally {
      if (onEnd) onEnd();
    }
  };
};

export const getHelp = ({ body = {}, onSuccess, onError, onEnd }) => {
  return async (dispatch) => {
    try {
      //showing loader on ui
      const res = await http.post({
        body,
        url: "v1/partner/auth/help",
        headers: { "x-auth-token": storage.get("xAuthToken") },
      });
      onSuccess(res);
    } catch (error) {
      
      if (onError) onError(error);
      else handleErrors(error);
    } finally {
      if (onEnd) onEnd();
    }
  };
};
