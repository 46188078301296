import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { SpinnerDotted } from "spinners-react";
import { useNavigate } from "react-router-dom";
import { changePassword } from "../../../../store/api/auth";
import Toast from "../../../../Components/Toastify/Toastify";
import eyes from "./../../../../assets/HomeAssets/assets/images/eyes.png";
import eyes2 from "./../../../../assets/HomeAssets/assets/images/eyes2.png";

const Password = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const toggleOldPasswordVisibility = () => {
    setShowOldPassword(!showOldPassword);
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const userDetail = useSelector((store) => store.user.user.address) || {};
  const navigate = useNavigate();

  const initialValues = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  const validationSchema = Yup.object({
    oldPassword: Yup.string().required("Current Password is required"),
    newPassword: Yup.string()
      .required("Password is required")
      .matches(
        /^(?=.*?[A-Z])/,
        "Password must contain at least one uppercase letter"
      )
      .matches(
        /^(?=.*?[a-z])/,
        "Password must contain at least one lowercase letter"
      )
      .matches(/^(?=.*?[0-9])/, "Password must contain at least one number")
      .matches(
        /^(?=.*?[!@#$%^&*()])/,
        "Password must contain at least one special character"
      )
      .min(6, "Password must be at least 6 characters long"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });
  const formikProps = useFormik({ initialValues, validationSchema, onSubmit });
  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    errors,
    touched,
    setFieldValue,
  } = formikProps;

  const handleCancel = () => {
    const {
      oldPassword = "",
      newPassword = "",
      confirmPassword = "",
    } = userDetail;

    setFieldValue("oldPassword", oldPassword);
    setFieldValue("newPassword", newPassword);
    setFieldValue("confirmPassword", confirmPassword);
  };

  async function onSubmit(values) {
    setLoading(true);

    dispatch(
      changePassword({
        body: {
          oldPassword: values.oldPassword,
          newPassword: values.newPassword,
        },

        onSuccess: (res) => {
          setLoading(false);
          if (res.data.success) {
            Toast.success(res.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
          } else {
            if (
              res.data.success === false &&
              (res.data.message ===
                "Your Session has been expired, Kindly sign in to continue." ||
                res.data.message ===
                  "We cant log you in. Kindly talk to customer support for further clarity.")
            ) {
              localStorage.clear();
              navigate("/");
              Toast.error(res.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
            } else {
              Toast.error(res.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
            }
          }
        },
        onError: (err) => {
          setLoading(false);
        },
      })
    );
  }
  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="form_box input-box">
        <div className="input-box-field form-floating">
            <input
              type={showOldPassword ? "text" : "password"}
              className="form-control login-form-control"
              id="floatingPassword"
              placeholder="Password"
              onChange={handleChange("oldPassword")}
              onBlur={handleBlur("oldPassword")}
              value={values.oldPassword}
            />
            <img
              src={showOldPassword ? eyes2 : eyes}
              className="showeye"
              onClick={toggleOldPasswordVisibility}
              alt="showButton"
            />
            <label htmlFor="floatingPassword login-label">Current Password</label>
          </div>
          {errors.oldPassword && touched.oldPassword ? (
            <div
              style={{
                color: "red",
                marginBottom: "5px",
                marginLeft: "5px",
              }}
            >
              <small>{errors.oldPassword}</small>
            </div>
          ) : null}
          <div className="input-box-field form-floating">
            <input
              type={showPassword ? "text" : "password"}
              className="form-control login-form-control"
              id="floatingPassword"
              placeholder="Password"
              onChange={handleChange("newPassword")}
              onBlur={handleBlur("newPassword")}
              value={values.newPassword}
            />
            <img
              src={showPassword ? eyes2 : eyes}
              className="showeye"
              onClick={togglePasswordVisibility}
              alt="showButton"
            />
            <label htmlFor="floatingPassword login-label">New Password</label>
          </div>
          {errors.newPassword && touched.newPassword ? (
            <div
              style={{
                color: "red",
                marginBottom: "5px",
                marginLeft: "5px",
              }}
            >
              <small>{errors.newPassword}</small>
            </div>
          ) : null}
          <div className="input-box-field form-floating">
            <input
              // type="password"
              type={showConfirmPassword ? "text" : "password"}
              className="form-control login-form-control"
              id="floatingPassword"
              placeholder="Confirm Password"
              onChange={handleChange("confirmPassword")}
              onBlur={handleBlur("confirmPassword")}
              value={values.confirmPassword}
            />
            <img
              src={showConfirmPassword ? eyes2 : eyes}
              className="showeye"
              onClick={toggleConfirmPasswordVisibility}
              alt="showButton"
            />
            <label htmlFor="floatingPassword login-label">
              Confirm Password
            </label>
          </div>
          {errors.confirmPassword && touched.confirmPassword ? (
            <div style={{ color: "red", marginLeft: "5px" }}>
              <small>{errors.confirmPassword}</small>
            </div>
          ) : null}
          <div className="form-btn d-flex mt-64">
            <button
              type="button"
              className="themebtn-dark w-100 mr-16 themeButtonDark"
              onClick={handleCancel}
            >
              {" "}
              Cancel{" "}
            </button>
            <button
              type="button"
              className="themebtn-blue w-100 themeButton"
              onClick={handleSubmit}
              disabled={loading ? true : false}
            >
              {loading ? (
                <SpinnerDotted style={{ height: "40", color: "#011343" }} />
              ) : (
                "Update"
              )}
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default Password;
