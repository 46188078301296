import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { SpinnerDotted } from "spinners-react";
import { useNavigate } from "react-router-dom";
import { updateAddess } from "../../../../store/api/auth";
import Toast from "../../../../Components/Toastify/Toastify";
import { setUser } from "../../../../store/auth/user";

const Address = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const userDetail = useSelector((store) => store.user.user.address) || {};
  const navigate = useNavigate();

  const initialValues = {
    street: "",
    city: "",
    state: "",
    zipCode: "",
  };

  const validationSchema = Yup.object({
    street: Yup.string().required("Required").trim().min(5, "Minimum length is 5").max(300, "Maximum length is 300"),
    city: Yup.string().required("Required").trim().min(3, "Minimum length is 3").max(100, "Maximum length is 100"),
    state: Yup.string().required("Required").trim().min(2, "Minimum length is 2").max(50, "Maximum length is 50"),
    zipCode: Yup.string().required("Required").min(4, "Minimum length is 4").max(10, "Maximum length is 10").trim(),
  });
  const formikProps = useFormik({ initialValues, validationSchema, onSubmit });
  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    errors,
    touched,
    setFieldValue,
  } = formikProps;

  useEffect(() => {
    const { street = "", city = "", state = "", zipCode = "" } = userDetail;

    setFieldValue("street", street);
    setFieldValue("city", city);
    setFieldValue("state", state);
    setFieldValue("zipCode", zipCode);
  }, [setFieldValue, userDetail]);

  const handleCancel = () => {
    const { street = "", city = "", state = "", zipCode = "" } = userDetail;

    setFieldValue("street", street);
    setFieldValue("city", city);
    setFieldValue("state", state);
    setFieldValue("zipCode", zipCode);
  };

  async function onSubmit(userEdit) {
    setLoading(true);

    dispatch(
      updateAddess({
        body: {
          address: {
            street: userEdit.street.trim(),
            city: userEdit.city.trim(),
            state: userEdit.state.trim(),
            zipCode: `${userEdit.zipCode.trim()}`,
          },
        },

        onSuccess: (res) => {
          setLoading(false);
          if (res.data.success) {
            dispatch(setUser(res?.data?.user));
            Toast.success(res.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
          } else {
            if (
              res.data.success === false &&
              (res.data.message ===
                "Your Session has been expired, Kindly sign in to continue." ||
                res.data.message ===
                  "We cant log you in. Kindly talk to customer support for further clarity.")
            ) {
              localStorage.clear();
              navigate("/");
              Toast.error(res.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
            } else {
              Toast.error(res.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
            }
          }
        },
        onError: (err) => {
          setLoading(false);
        },
      })
    );
  }
  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="form_box input-box">
          <div className="input-box-field form-floating">
            <input
              type="text"
              className="form-control login-form-control"
              id="Street"
              placeholder="1600 Pennsylvania Avenue NW"
              autoComplete="off"
              onChange={handleChange("street")}
              onBlur={handleBlur("street")}
              value={values.street}
            />
            <label htmlFor="Street">Street</label>
            {errors.street && touched.street ? (
              <div
                style={{
                  textAlign: "right",
                  color: "#FF0044",
                }}
              >
                <small
                  style={{
                    fontSize: "14px",
                  }}
                >
                  {errors.street}
                </small>
              </div>
            ) : null}
          </div>
          <div className="input-box-field form-floating">
            <input
              type="text"
              className="form-control login-form-control"
              id="City"
              placeholder="Washington  "
              autoComplete="off"
              onChange={handleChange("city")}
              onBlur={handleBlur("city")}
              value={values.city}
            />
            <label htmlFor="City">City</label>
            {errors.city && touched.city ? (
              <div
                style={{
                  textAlign: "right",
                  color: "#FF0044",
                }}
              >
                <small
                  style={{
                    fontSize: "14px",
                  }}
                >
                  {errors.city}
                </small>
              </div>
            ) : null}
          </div>
          <div className="input-box-field form-floating">
            <input
              type="text"
              className="form-control login-form-control"
              id="State"
              placeholder="District of Columbia"
              autoComplete="off"
              onChange={handleChange("state")}
              onBlur={handleBlur("state")}
              value={values.state}
            />
            <label htmlFor="State">State</label>
            {errors.state && touched.state ? (
              <div
                style={{
                  textAlign: "right",
                  color: "#FF0044",
                }}
              >
                <small
                  style={{
                    fontSize: "14px",
                  }}
                >
                  {errors.state}
                </small>
              </div>
            ) : null}
          </div>
          <div className="input-box-field form-floating">
            <input
              type="text"
              className="form-control login-form-control"
              id="Zipcode"
              placeholder="Zip code "
              autoComplete="off"
              onChange={handleChange("zipCode")}
              onBlur={handleBlur("zipCode")}
              value={values.zipCode}
            />
            <label htmlFor="Zipcode">Zip code</label>
            {errors.zipCode && touched.zipCode ? (
              <div
                style={{
                  textAlign: "right",
                  color: "#FF0044",
                }}
              >
                <small
                  style={{
                    fontSize: "14px",
                  }}
                >
                  {errors.zipCode}
                </small>
              </div>
            ) : null}
          </div>
          <div className="form-btn d-flex mt-64">
            <button
              type="button"
              className="themebtn-dark w-100 mr-16 themeButtonDark"
              onClick={handleCancel}
            >
              {" "}
              Cancel{" "}
            </button>
            <button
              type="button"
              className="themebtn-blue w-100 themeButton"
              onClick={handleSubmit}
              disabled={loading ? true : false}
            >
              {loading ? (
                <SpinnerDotted style={{ height: "40", color: "#011343" }} />
              ) : (
                "Update"
              )}
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default Address;
