import React from "react";
import { Navigate } from "react-router-dom";
import GetStorage from "../services/storage";

const Redirect = ({ children }) => {
  const xAuthToken = GetStorage.get("xAuthToken");
  const user = GetStorage.get("user");
  if (user?.onBoarded && xAuthToken) {
    return <Navigate to="/dashboard" replace />;
  }
  return children;
};
export default Redirect;
