import { useFormik } from "formik";
import "yup-phone";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { SpinnerDotted } from "spinners-react";
import { updateBasic } from "../../../../store/api/auth";
import Toast from "../../../../Components/Toastify/Toastify";
import { getUser } from "../../../../store/api/userEdit";
import { setUser } from "../../../../store/auth/user";

const Basic = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  // const phoneRegExp = /^\+?1?[-.\s]?\(?(\d{3})\)?[-.\s]?(\d{3})[-.\s]?(\d{4})$/;
  const userDetail = useSelector((store) => store.user.user) || {};
  const navigate = useNavigate();

  useEffect(() => {
    setPageLoading(true);
    dispatch(
      getUser({
        onSuccess: (res) => {
          setPageLoading(false);
          if (res.data.success) {
            //
          } else {
            if (
              res.data.success === false &&
              (res.data.message ===
                "Your Session has been expired, Kindly sign in to continue." ||
                res.data.message ===
                  "We cant log you in. Kindly talk to customer support for further clarity.")
            ) {
              localStorage.clear();
              navigate("/");
              Toast.error(res.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
            } else {
              Toast.error(res.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
            }
          }
        },
        onError: (err) => {
          setPageLoading(false);
        },
      })
    );
  }, [dispatch, navigate]);

  const initialValues = {
    firstname: "",
    lastname: "",
    username: "",
    phone: "",
  };

  const validationSchema = Yup.object({
    firstname: Yup.string()
      .required("Firstname is required")
      .min(2, "First Name must be at least 2 characters")
      .max(80, "Maximum length is 80").trim(),
    lastname: Yup.string()
      .required("Lastname is required")
      .min(2, "Last Name must be at least 2 characters")
      .max(80, "Maximum length is 80").trim(),
    phone: Yup.string()
      .test(
        "phone-validation",
        "Please enter a valid number",
        function (value) {
          const phoneNumber = parsePhoneNumberFromString(value);
          return phoneNumber && phoneNumber.isValid();
        }
      )
      .required("Please enter a valid number"),
  });

  const formikProps = useFormik({ initialValues, validationSchema, onSubmit });

  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    errors,
    touched,
    setFieldValue,
  } = formikProps;

  useEffect(() => {
    const {
      username = "",
      firstname = "",
      lastname = "",
      phone = "",
    } = userDetail;

    setFieldValue("username", username);
    setFieldValue("firstname", firstname);
    setFieldValue("lastname", lastname);
    setFieldValue("phone", phone);
  }, [userDetail]);

  const handleCancel = () => {
    const {
      username = "",
      firstname = "",
      lastname = "",
      phone = "",
    } = userDetail;

    setFieldValue("username", username);
    setFieldValue("firstname", firstname);
    setFieldValue("lastname", lastname);
    setFieldValue("phone", phone);
  };

  async function onSubmit(userEdit) {
    setLoading(true);

    dispatch(
      updateBasic({
        body: {
          username: userEdit.username,
          firstname: userEdit.firstname.trim(),
          lastname: userEdit.lastname.trim(),
          phone: `${userEdit.phone}`,
        },

        onSuccess: (res) => {
          setLoading(false);
          if (res.data.success) {
            dispatch(setUser(res?.data?.user));
            Toast.success(res.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
          } else {
            if (
              res.data.success === false &&
              (res.data.message ===
                "Your Session has been expired, Kindly sign in to continue." ||
                res.data.message ===
                  "We cant log you in. Kindly talk to customer support for further clarity.")
            ) {
              localStorage.clear();
              navigate("/");
              Toast.error(res.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
            } else {
              Toast.error(res.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
            }
          }
        },
        onError: (err) => {
          setLoading(false);
        },
      })
    );
  }
  return (
    <>
      {pageLoading ? (
        <div className="showLoader">
          <SpinnerDotted style={{ height: "30", color: "white" }} />
        </div>
      ) : (
        ""
      )}
      <form onSubmit={handleSubmit}>
        <div className="form_box input-box">
          <div className="input-box-field form-floating">
            <input
              type="text"
              className="form-control login-form-control"
              id="Username"
              placeholder="Username"
              autoComplete="off"
              onChange={handleChange("username")}
              onBlur={handleBlur("username")}
              value={values.username}
              readOnly
            />
            <label htmlFor="Username">Username</label>
          </div>

          <div className="input-box-field form-floating">
            <input
              type="text"
              className="form-control login-form-control"
              id="Firstname"
              placeholder="First name"
              autoComplete="off"
              onChange={handleChange("firstname")}
              onBlur={handleBlur("firstname")}
              value={values.firstname}
            />
            <label htmlFor="Firstname">First name</label>
          </div>
          {errors.firstname && touched.firstname ? (
            <div
              style={{
                textAlign: "right",
                color: "#FF0044",
                marginBottom: "8px",
                marginTop: "-8px",
              }}
            >
              <small
                style={{
                  fontSize: "14px",
                }}
              >
                {errors.firstname}
              </small>
            </div>
          ) : null}
          <div className="input-box-field form-floating">
            <input
              type="text"
              className="form-control login-form-control"
              id="Lastname"
              placeholder="Last name"
              autoComplete="off"
              onChange={handleChange("lastname")}
              onBlur={handleBlur("lastname")}
              value={values.lastname}
            />
            <label htmlFor="Lastname">Last name</label>
          </div>
          {errors.lastname && touched.lastname ? (
            <div
              style={{
                textAlign: "right",
                color: "#FF0044",
                marginBottom: "8px",
                marginTop: "-8px",
              }}
            >
              <small
                style={{
                  fontSize: "14px",
                }}
              >
                {errors.lastname}
              </small>
            </div>
          ) : null}
          <div className="input-box-field form-floating">
            <input
              type="tel"
              className="form-control login-form-control"
              id="Phonenumber"
              placeholder="Phone number"
              autoComplete="off"
              onChange={(e) => {
                let val = e.target.value;
                val = val.replace(/\D/g, "");
                val = val ? "+" + val.replaceAll("+", "") : "";
                handleChange("phone")(val);
              }}
              onBlur={handleBlur("phone")}
              value={values.phone}
            />
            <label htmlFor="Phonenumber">Phone number</label>
          </div>
          {errors.phone && touched.phone ? (
            <div
              style={{
                textAlign: "right",
                color: "#FF0044",
                marginBottom: "8px",
                marginTop: "-8px",
              }}
            >
              <small
                style={{
                  fontSize: "14px",
                }}
              >
                {errors.phone}
              </small>
            </div>
          ) : null}
          <div className="form-btn d-flex mt-64">
            <button
              type="button"
              className="themebtn-dark w-100 mr-16 themeButtonDark"
              onClick={handleCancel}
            >
              {" "}
              Cancel{" "}
            </button>
            <button
              type="button"
              className="themebtn-blue w-100 themeButton "
              onClick={handleSubmit}
              disabled={loading ? true : false}
            >
              {loading ? (
                <SpinnerDotted style={{ height: "40", color: "#011343" }} />
              ) : (
                "Update"
              )}
            </button>
          </div>
        </div>
      </form>
      {/* </>
        )}
      </Formik> */}
    </>
  );
};

export default Basic;
