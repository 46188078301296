import React, { useState } from "react";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { useSearchParams } from "react-router-dom";
import { SpinnerDotted } from "spinners-react";
import logo from "./../../assets/HomeAssets/assets/images/logo.png";
import eyes from "./../../assets/HomeAssets/assets/images/eyes.png";
import eyes2 from "./../../assets/HomeAssets/assets/images/eyes2.png";
import { newPassword } from "../../store/api/auth";
import back from "./../../assets/HomeAssets/assets/images/back.png";
import Toast from "../../Components/Toastify/Toastify";
import { updateValue } from "../../store/ui/userData";
import Header from "../../Components/Layout/Header/Header";
import { Helmet } from "react-helmet";

export default function NewPassword() {
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");
  const [loading, setLoading] = useState(false);
  const passwordResetCode = searchParams.get("passwordResetCode");
  const navigate = useNavigate();
  const { id } = useParams();
  const initialValues = {
    confirmPassword: "",
    password: "",
  };
  const validationSchema = Yup.object({
    password: Yup.string()
      .required("Required")
      .min(6, "Minimum length must be 6 characters"),
    confirmPassword: Yup.string()
      .min(6, "Minimum length must be 6 characters")
      .oneOf([Yup.ref("password"), null], "The passwords do not mach")
      .required("Required"),
  });

  const [error, setError] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const dispatch = useDispatch();
  const onSubmit = async (values) => {
    setLoading(true);
    dispatch(updateValue(values.password));
    dispatch(
      newPassword({
        id: id,
        body: { password: values.password, email, passwordResetCode },

        onSuccess: (res) => {
          setLoading(false);
          if (res.data.success) {
            Toast.success(res.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
          } else {
            Toast.error(res.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
          }
          navigate("/");
        },

        onError: (error) => {
          setLoading(false);
          if (error.response) {
            if (error.response.data.errors) {
              for (let key in error.response.data.errors) {
                setError(error.response.data.errors[key]);
              }
            } else if (error.response.data) {
              setError(error.response.data);
            }
          }
        },
      })
    );
  };

  const formdata = () => {
    return (
      <>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            errors,
            touched,
          }) => (
            <>
              <div className="login-form input-box">
                <div className="login-logo mb-32 mx-auto text-center">
                  <img src={logo} alt="logo" />
                </div>
                <h1 className="f36 text-center ">Choose New Password</h1>
                <h1 className="f16b text-center mt-17">
                  Almost done. Enter and confirm your new password
                </h1>
                <div className="login-field mt-32">
                  <div className="input-box-field form-floating">
                    <input
                      type={showPassword ? "text" : "password"}
                      className="form-control login-form-control"
                      id="floatingPassword"
                      placeholder="Password"
                      onChange={handleChange("password")}
                      onBlur={handleBlur("password")}
                      value={values.password}
                    />
                    <img
                      src={showPassword ? eyes2 : eyes}
                      className="showeye"
                      onClick={togglePasswordVisibility}
                      alt="showButton"
                    />
                    <label htmlFor="floatingPassword login-label">
                      Password
                    </label>
                  </div>
                  {errors.password && touched.password ? (
                    <div
                      style={{
                        color: "red",
                        marginBottom: "5px",
                        marginLeft: "5px",
                      }}
                    >
                      <small>{errors.password}</small>
                    </div>
                  ) : null}
                  <div className="input-box-field form-floating">
                    <input
                      // type="password"
                      type={showConfirmPassword ? "text" : "password"}
                      className="form-control login-form-control"
                      id="floatingPassword"
                      placeholder="Confirm Password"
                      onChange={handleChange("confirmPassword")}
                      onBlur={handleBlur("confirmPassword")}
                      value={values.confirmPassword}
                    />
                    <img
                      src={showConfirmPassword ? eyes2 : eyes}
                      className="showeye"
                      onClick={toggleConfirmPasswordVisibility}
                      alt="showButton"
                    />
                    <label htmlFor="floatingPassword login-label">
                      Confirm Password
                    </label>
                  </div>
                  {errors.confirmPassword && touched.confirmPassword ? (
                    <div style={{ color: "red", marginLeft: "5px" }}>
                      <small>{errors.confirmPassword}</small>
                    </div>
                  ) : null}

                  {error ? (
                    <>
                      <div
                        style={{
                          color: "red",
                          textAlign: "center",
                        }}
                      >
                        <small>{error}</small>
                      </div>
                    </>
                  ) : null}
                  <button
                    className="themebtn-blue w-100 mt-32"
                    onClick={handleSubmit}
                    disabled={loading ? true : false}
                  >
                    {loading ? (
                      <SpinnerDotted
                        style={{ height: "40", color: "#011343" }}
                      />
                    ) : (
                      "Reset password"
                    )}
                  </button>
                  <div className="back back_btn mt-17 d-flex align-items-center">
                    <img src={back} alt="back" onClick={() => navigate("/")} />
                    <h1
                      className="f16 mb-0 hover"
                      onClick={() => navigate("/")}
                    >
                      Back to log In
                    </h1>
                  </div>
                </div>
              </div>
            </>
          )}
        </Formik>
      </>
    );
  };
  return (
    <>
    <Helmet>
        <title>Lionsden Partner Portal - New Password</title>
        <meta
          name="description"
          content="LionsDen Games - Unleash Your Inner Lion - The Ultimate Sweepstakes Casino Experience in America. Experience the thrill, embrace the excitement, and ignite your passion at LionsDen Games – your ultimate online adventure!"
        />
      </Helmet>
      <div className="bg_wrap scroll-container2">
        <Header />
        <div className="formBox_main">
          <div className="formBox">
            <div className="login-body">
              <div className="wrap-login-body mt2">{formdata()}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
