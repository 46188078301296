import { React, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { SpinnerDotted } from "spinners-react";

const ParentProfitTable = ({loading, usersData, currentPage, perPage}) => {


  return (
    <>
      <div>
        {/* <h1 className="f24 mb-0 text-white mt-64 ">Payout History</h1> */}

        <div className="table_width3 PayoutHistory-table mt-32">
          <div className="table_width3">
            {loading ? (
              <div className="table-loader">
                <SpinnerDotted style={{ height: "30", color: "white" }} />
              </div>
            ) : (
              <div className="custom_table table-wrapper">
                <table className="table giro-table table-bordered">
                  <thead>
                    <tr>
                      <th className="br-r-8">#</th>
                      <th className="">Email</th>
                      <th className="">Username</th>
                      {/* <th className="">Payout %</th> */}
                      <th className="">Total Profit</th>
                      <th className="">Partner Share</th>
                      <th className="">Parent Share</th>
                    </tr>
                  </thead>
                  <tbody className="scroll_bar  ">
                    {usersData?.length > 0 ? (
                      <>
                        {usersData?.map((item, key) => (
                          <tr key={key}>
                            <td className="br-r-8">
                              {(currentPage - 1) * perPage + key + 1}
                            </td>
                            <td className="">{item?.email}</td>
                            <td className="">{item?.username}</td>
                            {/* <td className="">{item?.payoutPercentage}</td> */}
                            <td>{item?.parentComission + item?.partnerComission || 0}</td>
                            <td>{item?.partnerComission}</td>
                            <td>{item?.parentComission}</td>
                          </tr>
                        ))}
                      </>
                    ) : (
                      <>
                        <h1 className="clr-white" style={{ fontSize: "28px", color: 'white' }}>
                          No Profits available
                        </h1>
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ParentProfitTable;
