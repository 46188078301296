import React from "react";
import { Navigate } from "react-router-dom";
import GetStorage from "../services/storage";

const ProtectedRoutes = ({ children }) => {
  const xAuthToken = GetStorage.get("xAuthToken");
  if (!xAuthToken) {
    return <Navigate to="/" replace />;
  }
  return children;
};
export default ProtectedRoutes;


export const AuthGuard = ({ children }) => {
  const xAuthToken = GetStorage.get("xAuthToken");

  const user = GetStorage.get("user");
  if (!user.onBoarded && xAuthToken) {
    return <Navigate to="/on-boarding" replace />;
  }
  return children;
};
