import { React } from "react";
import { SpinnerDotted } from "spinners-react";
import { AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai";
import { TimestampComponent } from "../../../../services/number.services";

const UnApprovedUsersTable = ({
  loading,
  usersData,
  currentPage,
  perPage,
  isActive,
  order,
  onClickColumn,
}) => {
  // console.log("usersData affiliate", usersData);
  return (
    <>
      <div>
        {/* <h1 className="f24 mb-0 text-white mt-64 ">Payout History</h1> */}

        <div className="table_width3 PayoutHistory-table mt-32">
          {/* <div className=""> */}
          {loading ? (
            <div className="table-loader">
              <SpinnerDotted style={{ height: "30", color: "white" }} />
            </div>
          ) : (
            <div className="custom_table table-wrapper">
              <table className="table giro-table table-bordered">
                <thead>
                  <tr>
                    <th className="br-r-8">#</th>
                    <th
                      className="pointer"
                      onClick={() => onClickColumn("username")}
                    >
                      Username{" "}
                      {isActive === "" ? (
                        ""
                      ) : isActive === "username" ? (
                        order === "asc" ? (
                          <AiOutlineArrowDown size="20px" color="#aab3d2" />
                        ) : (
                          <AiOutlineArrowUp size="20px" color="#aab3d2" />
                        )
                      ) : (
                        ""
                      )}
                    </th>
                    <th className="">Jumio Status</th>
                    <th
                      className="pointer"
                      onClick={() => onClickColumn("createdAt")}
                    >
                      Date of Joining{" "}
                      {isActive === "" ? (
                        ""
                      ) : isActive === "createdAt" ? (
                        order === "asc" ? (
                          <AiOutlineArrowDown size="20px" color="#aab3d2" />
                        ) : (
                          <AiOutlineArrowUp size="20px" color="#aab3d2" />
                        )
                      ) : (
                        ""
                      )}
                    </th>
                  </tr>
                </thead>
                <tbody className="scroll_bar  ">
                  {usersData?.length > 0 ? (
                    <>
                      {usersData?.map((item, key) => (
                        <tr key={key}>
                          <td className="br-r-8">
                            {(currentPage - 1) * perPage + key + 1}
                          </td>
                          <td className="">{item?.username}</td>
                          <td>{item?.jumioStatus}</td>
                          <td>
                            <TimestampComponent
                              isoTimestamp={item?.createdAt}
                            />
                          </td>
                        </tr>
                      ))}
                    </>
                  ) : (
                    <>
                      <h1
                        className="clr-white"
                        style={{ fontSize: "28px", color: "white" }}
                      >
                        No Unapproved Users
                      </h1>
                    </>
                  )}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default UnApprovedUsersTable;
