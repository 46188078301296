import React, { useState } from "react";
import { InputGroup, FormControl } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { Search } from "react-bootstrap-icons";

const SelectOptions = ({ selectHandler, options, defaultValue }) => {
  // const [inputValue, setInputValue] = useState("");
  const [selectedValue, setSelectedValue] = useState(defaultValue);

  // const handleKeyPress = (event) => {
  //   if (event.key === "Enter") {
  //     selectHandler(event.target.value);
  //     // setInputValue('')
  //   }
  // };

  // const searchClick = () => {
  //   // console.log("input value", inputValue);
  //   selectHandler(inputValue);
  //   // setInputValue('')
  // };
  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
  const value = Number(event.target.value);
    // console.log('value', value);
    selectHandler(value);
  };
  return (
    <Form.Group   controlId="customSelect">
      {/* <Form.Label>Per Page limit</Form.Label> */}
      <Form.Select className="custom-select" value={selectedValue} onChange={handleSelectChange} size="lg"  style={{
        backgroundColor: '#33363E', // Change to your desired color
        color: 'white', // Set text color to ensure visibility
        borderRadius: '8px', // Optional: to match rounded corners
        border: 'none', // Optional: remove border if desired
        maxHeight: '45px',
        fontSize: '16px',
    }}>
        {/* {options.map((option) => (
          <option key={option.id} value={option.value}>{option.label}</option>
        ))} */}
        <option value={10}>10</option>
        <option value={20}>20</option>
        <option value={50}>50</option>
        <option value={100}>100</option>
      </Form.Select>
    </Form.Group>
  );
};

export default SelectOptions;
