import React, { useEffect, useState } from "react";
import {  useDispatch } from "react-redux";
import Toast from "../../../Components/Toastify/Toastify";

// images for cashouts
// import DeleteImg from "../../../assets/HomeAssets/assets/images/settings/del-icon.png";
// import mastercard from "../../../assets/images/mastercard.png";
// import visacard from "../../../assets/images/visa-card.png";
// import americanexpress from "../../../assets/images/amex.png";
// import discover from "../../../assets/images/discover.png";
// import defaultCard from "../../../assets/images/default.png";
// import Setting from "../../../assets/HomeAssets/assets/images/settings/setting.png";
// import arrowDown from "../../../assets/images/downArrow.png";
// import GoldCoin from "../../../assets/HomeAssets/assets/images/settings/lionspidecoin.png";


import { SpinnerDotted } from "spinners-react";

// All API's
// import {
//   AchAddBank,
//   cashout,
//   cashoutwithdraw,
//   deleteCard,
//   deleteUsersSavedCards,
//   deleteUsersSavedCardsAch,
//   getCashoutDetails,
//   getConversionRate,
//   getUsersCardApi,
//   getWhiteListedStates,
//   paymentApi,
// } from "../../../store/api/cashout";
// import { getUser } from "../../../store/api/userEdit";
import { useNavigate } from "react-router-dom";
// import {
//   deleteUserCard,
//   deleteUserCardAch,
// } from "../../../store/ui/cashout.slice";
import { toast } from "react-toastify";

import { Link } from "react-router-dom";
import SideBar from "../../../Components/Layout/SideBar/SideBar";
import DashboardHeader from "../../../Components/Layout/Header/DashboardHeader";
import Banknote from "../../../assets/HomeAssets/assets/images/icon/Banknote.png";
import setting from "../../../assets/HomeAssets/assets/images/icon/setting.png";
import { Helmet } from "react-helmet";
import UsersTable from "./UsersTable.js/UsersTable";
import { baseUrl } from "../../../config";
import storage from "../../../services/storage";
import axios from "axios";
import CustomPagination from "../../../Components/Layout/CustomPagination/CustomPagination";
import UnApprovedUsersTable from "../../../Components/Layout/Tables/AllUsersTable/AllUsersTable";
import SelectOptions from "../../../Components/Layout/CustomSelectOptions/SelectOptions";
import SearchInput from "../../../Components/Layout/CustomSearchInput/SearchInput";
// import PayoutHistory from "./PayoutHistory/PayoutHistory";

const UnApprovedUsers = () => {
  const [loading, setLoading] = useState(false);
  const [usersData, setUsersData] = useState([]);
  // const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [sortBy, setSortBy] = useState("createdAt");
  const [orderBy, setOrderBy] = useState("desc");
  const [searchBy, setSearchBy] = useState("");
  const [perPage, setPerPage] = useState(10);
  const [totalUsers, setTotalUsers] = useState(0);
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");
  const fetchData = async () => {
    setLoading(true);
    let data = JSON.stringify({
      page: currentPage,
      perPage: perPage,
      search: searchBy,
      orderBy: orderBy,
      sortBy: sortBy,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${baseUrl}v1/partner/referral/unapproved`,
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": `${storage.get("xAuthToken")}`,
      },
      data: data,
    };

    await axios
      .request(config)
      .then((res) => {
        setUsersData(res.data?.data || []);
        setTotalPages(res.data?.totalPages || 0);
        setTotalUsers(res.data?.totalCount || 0);
        setLoading(false);
        if (
          (res.data.success === false &&
            res.data.message ===
              "Your Session has been expired, Kindly sign in to continue.") ||
          res.data.message ===
            "Sorry! Services not available in your country." ||
          res.data.message ===
            "Sorry! Services not available in your region." ||
          res.data.message === "You cannot use LionsDen with a VPN or Proxy."
        ) {
          storage.remove("xAuthToken");
          storage.remove("user");
          // SocketService.disconnect();
          // history.push("/authentication/sign-in");
          Toast.error(res.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }
      })
      .catch((error) => {});
  };

  const handleLimitChange = (value) => {
    setPerPage(value);
    setCurrentPage(1);
  };
  const clearSearch = () => {
    setSearchBy("");
  };
  const searchHandler = (value) => {
    setSearchBy(value);
    setCurrentPage(1);
  };
  useEffect(() => {
    // console.log("fetching data");
    fetchData();
  }, [perPage, currentPage, searchBy, orderBy, sortBy]);
  

  const handleLoadMore = () => {
    dispatch(setLoading(true));
  };
  function formatDate(dateString) {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, options);
  }

  // new code ends here
  const [isInputVisible, setInputVisible] = useState(false);

  const handleSearchClick = () => {
    setInputVisible(!isInputVisible);
  };
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const downloadCSVFile = async () => {
    const token = localStorage.getItem("xAuthToken"); // Retrieve the token from localStorage
    setLoading(true);
    try {
      const response = await fetch(
        `${baseUrl}v1/partner/referral/unapproved/csv`,
        {
          method: "GET",
          headers: {
            "x-auth-token": token, // Correctly format the header name
            "Content-Type": "application/json",
            "Cache-Control": "no-cache",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const blob = await response.blob(); // Convert the response into a Blob
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Unverified Users.csv`); // or whatever you want the filename to be
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setLoading(false);
      console.error("File Downloaded");
    } catch (error) {
      console.error("Error downloading the file", error);
      setLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Lionsden Partner Portal - Unapproved Users</title>
        <meta
          name="description"
          content="LionsDen Games - Unleash Your Inner Lion - The Ultimate Sweepstakes Casino Experience in America. Experience the thrill, embrace the excitement, and ignite your passion at LionsDen Games – your ultimate online adventure!"
        />
      </Helmet> 
      <div className="wrapper scroll-container2">
        <SideBar />
        <div id="content">
          <div className="content_inner">
            <DashboardHeader />
            <div className="dashboard-body">
              <div className="head_wrap d-flex align-items-center justify-content-between">
                <h1 className="col-md-3 f24 mb-0 text-white">Unapproved Users List</h1>
                <div className="col-md-9 d-flex justify-content-end align-items-center">
                  <span className="limit-span mr-16">
                    <p className="f16 mr-8 mb-0">Per page limit</p>
                    <SelectOptions
                    selectHandler={handleLimitChange}
                    // options={options}
                    defaultValue={perPage}
                  /></span>
                  
                  <SearchInput searchHandler={searchHandler} />
                  {/* {searchBy !== "" ? (
                    <button className="themeButtonLedger" onClick={clearSearch}>
                      Clear
                    </button>
                  ) : (
                    ""
                  )} */}
                  <div className="filter_icon">
                  <button
                    type="button"
                    className="themebtn-blue w-100 themeButton"
                    onClick={downloadCSVFile}
                    disabled={loading ? true : false}
                  >
                    {loading ? (
                      <SpinnerDotted
                        style={{  width: "150px", height: "35px", color: "#011343" }}
                      />
                    ) : (
                      "Download CSV"
                    )}
                  </button>
                </div>
                </div>

                
              </div>

              <div className="payment-wrap">
                {/* <UsersTable /> */}
                {searchBy !== "" && (
                  <>
                    <div className="head_wrap d-flex align-items-center justify-content-between">
                      <h1 className="f24 mb-0 text-white">
                        Search Results of {searchBy}
                      </h1>
                    </div>
                  </>
                )}
                <UnApprovedUsersTable
                  usersData={usersData}
                  loading={loading}
                  currentPage={currentPage}
                  perPage={perPage}
                  order={orderBy}
                  isActive={sortBy}
                  onClickColumn={(columnName) => {
                    setSortBy((prevColumnName) => {
                      if (columnName === prevColumnName)
                        setOrderBy((prev) => (prev === "desc" ? "asc" : "desc"));
                      else setOrderBy("desc");

                      return columnName;
                    });
                  }}
                />
                {usersData?.length > 0 && (
                  <CustomPagination
                    handlePageChange={handlePageChange}
                    currentPage={currentPage}
                    totalPages={totalPages}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UnApprovedUsers;
