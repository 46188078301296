import React, { useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { SpinnerDotted } from "spinners-react";
import { getHelp } from "../../../store/api/auth";
import Toast from "../../../Components/Toastify/Toastify";
import SideBar from "../../../Components/Layout/SideBar/SideBar";
import DashboardHeader from "../../../Components/Layout/Header/DashboardHeader";
import { Helmet } from "react-helmet";

const Gethelp = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [showSuccessMessage, setShowSuccessMessage] = useState("");
  const [showErrorMessage, setShowErrorMessage] = useState();

  const initialValues = {
    name: "",
    email: "",
    subject: "",
    message: "",
  };
  const validationSchema = Yup.object({
    name: Yup.string().required("Required").trim().min(2, 'Name must be at least 2 characters').max(80, "Maximum length is 80"),
    email: Yup.string().email("Invalid email format").required("Required"),
    subject: Yup.string().required("Required").trim().min(2, 'Subject must be at least 2 characters').max(80, "Maximum length is 80"),
    message: Yup.string().required("Required").trim().min(2, 'Message must be at least 2 characters').max(180, "Maximum length is 180"),
  });
  const onSubmit = async (values) => {
    setLoading(true);

    //   dispatch(setCredational(values));
    dispatch(
      getHelp({
        body: {
          name: values.name.trim(),
          email: values.email.trim(),
          subject: values.subject.trim(),
          message: values.message.trim(),
        },
        onSuccess: (res) => {
          setLoading(false);
          if (res.data.success) {
            setShowSuccessMessage(res.data.message);
            Toast.success(res.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
          } else {
            setShowErrorMessage(res.data.message);
            if (
              res.data.success == false &&
              (res.data.message ===
                "Your Session has been expired, Kindly sign in to continue." ||
                res.data.message ===
                  "We cant log you in. Kindly talk to customer support for further clarity.")
            ) {
              localStorage.clear();
              navigate("/");
              Toast.error(res.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
            } else {
              Toast.error(res.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
            }
          }
        },
        onError: (error) => {
          setLoading(false);
          if (error.response) {
            if (error.response.data.errors) {
              for (let key in error.response.data.errors) {
                setError(error.response.data.errors[key]);
                Toast.error(error.response.data.errors[key], {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "dark",
                });
              }
            } else if (error.response.data.message) {
              setError(error.response.data.message);
              Toast.error(error.response.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
            }
          }
        },
      })
    );
  };

  return (
    <>
    <Helmet>
        <title>Lionsden Partner Portal - Get Help</title>
        <meta
          name="description"
          content="LionsDen Games - Unleash Your Inner Lion - The Ultimate Sweepstakes Casino Experience in America. Experience the thrill, embrace the excitement, and ignite your passion at LionsDen Games – your ultimate online adventure!"
        />
      </Helmet>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          errors,
          touched,
        }) => (
          <>
            <div className="wrapper scroll-container2">
              <SideBar />
              <div id="content">
                <div className="content_inner">
                  <DashboardHeader />
                  <div className="dashboard-body">
                    <h1 className="f48 mb-0 text-white ">Get help</h1>
                    <div className="form_box input-box  mt-64 getHelpFormBox">
                      <div className="input-box-field form-floating">
                        <input
                          type="text"
                          className="form-control login-form-control"
                          id="Name"
                          placeholder="@abacchio"
                          autoComplete="off"
                          onChange={handleChange("name")}
                          onBlur={handleBlur("name")}
                          value={values.name}
                        />
                        <label htmlFor="Name">Name</label>
                        {errors.name && touched.name ? (
                          <div
                            style={{
                              textAlign: "right",
                              color: "#FF0044",
                            }}
                          >
                            <small
                              style={{
                                fontSize: "14px",
                              }}
                            >
                              {errors.name}
                            </small>
                          </div>
                        ) : null}
                      </div>
                      <div className="input-box-field form-floating">
                        <input
                          type="email"
                          className="form-control login-form-control"
                          id="Email"
                          placeholder="abacchio@gamblers.com  "
                          autoComplete="off"
                          onChange={handleChange("email")}
                          onBlur={handleBlur("email")}
                          value={values.email}
                        />
                        <label htmlFor="Email">Email Address</label>
                        {errors.email && touched.email ? (
                          <div
                            style={{
                              textAlign: "right",
                              color: "#FF0044",
                            }}
                          >
                            <small
                              style={{
                                fontSize: "14px",
                              }}
                            >
                              {errors.email}
                            </small>
                          </div>
                        ) : null}
                      </div>
                      <div className="input-box-field form-floating">
                        <input
                          type="text"
                          className="form-control login-form-control"
                          id="Subject"
                          placeholder="Help with my account"
                          autoComplete="off"
                          onChange={handleChange("subject")}
                          onBlur={handleBlur("subject")}
                          value={values.subject}
                        />
                        <label htmlFor="Subject">Subject</label>
                        {errors.subject && touched.subject ? (
                          <div
                            style={{
                              textAlign: "right",
                              color: "#FF0044",
                            }}
                          >
                            <small
                              style={{
                                fontSize: "14px",
                              }}
                            >
                              {errors.subject}
                            </small>
                          </div>
                        ) : null}
                      </div>
                      <div className="input-box-field form-floating">
                        <textarea
                          className="form-control login-form-control Message_box"
                          id="Message"
                          placeholder="Ask for help "
                          autoComplete="off"
                          onChange={handleChange("message")}
                          onBlur={handleBlur("message")}
                          value={values.message}
                        ></textarea>
                        <label htmlFor="Message">Message</label>
                        {errors.message && touched.message ? (
                          <div
                            style={{
                              textAlign: "right",
                              color: "#FF0044",
                            }}
                          >
                            <small
                              style={{
                                fontSize: "14px",
                              }}
                            >
                              {errors.message}
                            </small>
                          </div>
                        ) : null}
                      </div>
                      <div className="form-btn   mt-64">
                        <button
                          type="button"
                          className="themebtn-blue w-100"
                          onClick={handleSubmit}
                          disabled={loading ? true : false}
                        >
                          {loading ? (
                            <SpinnerDotted
                              style={{ height: "40", color: "#011343" }}
                            />
                          ) : (
                            "Send message"
                          )}
                        </button>
                        <div className="error_massage">
                          {showSuccessMessage ? (
                            <span className="error_box bg_success">
                              {showSuccessMessage}
                            </span>
                          ) : (
                            ""
                          )}
                          {showErrorMessage ? (
                            <span className="error_box bg_danger">
                              {showErrorMessage}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Formik>
    </>
  );
};

export default Gethelp;
