import http from "../../services/http";
import storage from "../../services/storage";
import { handleErrors } from "../../helpers/error";


export const createReferral = ({ body = {}, onSuccess, onError, onEnd }) => {
  return async (dispatch) => {
    try {
      //showing loader on uifs
      const res = await http.post({
        body,
        url: "v1/partner/referral",
        headers: { "x-auth-token": storage.get("xAuthToken") },
      });
      onSuccess(res);
    } catch (error) {
      if (onError) onError(error);
      else handleErrors(error);
    } finally {
      if (onEnd) onEnd();
    }
  };
};

export const getReferral = ({ body = {}, onSuccess, onError, onEnd }) => {

  return async (dispatch) => {
    try {
      //showing loader on uifs
      const res = await http.post({
        body,
        url: "v1/partner/referral/list",
        headers: { "x-auth-token": storage.get("xAuthToken") },
      });
      onSuccess(res);

    } catch (error) {
      if (onError) onError(error);
      else handleErrors(error);
    } finally {
      if (onEnd) onEnd();
    }
  };
};
