const phoneUtil =
  require("google-libphonenumber").PhoneNumberUtil.getInstance();
function validatePhoneNumber(phoneNumber) {
  try {
    const parsedNumber = phoneUtil.parseAndKeepRawInput(phoneNumber);
    return phoneUtil.isValidNumber(parsedNumber);
  } catch (error) {
    return false;
  }
}

const validateForm = (step, parthner) => {
  let error = {};

  switch (step) {
    case 1:
      error = {};

      if (!parthner.userName.trim()) {
        error.userName =
          "The username must be between 2 and 20 characters long";
      } else if (parthner.userName.trim().length < 2) {
        error.userName =
          "The username must be between 2 and 20 characters long";
      } else if (parthner.userName.trim().length > 20) {
        error.userName =
          "The username must be between 2 and 20 characters long";
      }

      if (!parthner.firstName.trim()) {
        error.firstName = "The name should be between 2 and 32 characters long";
      } else if (parthner.firstName.trim().length < 2) {
        error.firstName = "The name should be between 2 and 32 characters long";
      } else if (parthner.firstName.trim().length > 32) {
        error.firstName = "The name should be between 2 and 32 characters long";
      }
      if (!parthner.lastName.trim()) {
        error.lastName = "The name should be between 2 and 32 characters long";
      } else if (parthner.lastName.trim().length < 2) {
        error.lastName = "The name should be between 2 and 32 characters long";
      } else if (parthner.lastName.trim().length > 32) {
        error.lastName = "The name should be between 2 and 32 characters long";
      }

      if (!parthner.phoneNumber) {
        error.phoneNumber = "Please enter a valid number";
      } else if (
        !validatePhoneNumber(parthner.phoneNumber, parthner.countryCode)
      ) {
        error.phoneNumber = "Please enter a valid number";
      }

      break;
    case 2:
      error = {};
      if (!parthner.street) {
        error.street = `Required`;
      }else if (parthner.street.trim().length < 5) {
        error.street =
          "Street must be between 5 and 300 characters long";
      }else if (parthner.street.trim().length > 300) {
        error.street =
          "Street must be between 5 and 300 characters long";
      }
      if (!parthner.city) {
        error.city = "Required";
      }else if (parthner.city.trim().length < 3) {
        error.city =
          "City must be between 3 and 100 characters long";
      }else if (parthner.city.trim().length > 100) {
        error.city =
          "City must be between 3 and 100 characters long";
      }


      if (!parthner.state) {
        error.state = "Required";
      }else if (parthner.state.trim().length < 2) {

        error.state =
          "State must be between 2 and 50 characters long";
      }else if (parthner.state.trim().length > 50) {
        error.state =
          "State must be between 2 and 50 characters long";
      }



      if (!parthner.zipCode) {
        error.zipCode = "Required";
      }else if (parthner.zipCode.trim().length < 4) {

        error.zipCode =
          "ZipCode must be between 4 and 10 characters long";
      }else if (parthner.zipCode.trim().length > 10) {
        error.zipCode =
          "ZipCode must be between 4 and 10 characters long";
      }
      break;

    default:
      break;
  }

  return error;
};

export default validateForm;
