import { React } from "react";
import { SpinnerDotted } from "spinners-react";
import {
  formatNumber,
  TimestampComponent,
} from "../../../../services/number.services";
import { Link } from "react-router-dom";

const LedgerUserTable = ({ loading, usersData, currentPage, perPage }) => {
  // console.log("usersData affiliate", usersData);
  return (
    <>
      <div>
        {/* <h1 className="f24 mb-0 text-white mt-64 ">Payout History</h1> */}

        <div className="table_width3 PayoutHistory-table mt-32">
          <div className="">
            {loading ? (
              <div className="table-loader">
                <SpinnerDotted style={{ height: "30", color: "white" }} />
              </div>
            ) : (
              <div className="custom_table table-wrapper">
                <table className="table giro-table table-bordered">
                  <thead>
                    <tr>
                      <th className="br-r-8">#</th>
                      <th className="">Type</th>
                      <th className="">Status</th>
                      <th className="">Amount</th>
                      <th className="">Bet</th>
                      <th className="">Win</th>
                      <th className="">Jackpot</th>
                      <th className="">Updated Wallet</th>
                      <th className="">Reward Type</th>
                      <th className="">Record ID</th>
                      <th className="">Time</th>
                    </tr>
                  </thead>
                  <tbody className="scroll_bar  ">
                    {usersData?.length > 0 ? (
                      <>
                        {usersData?.map((item, key) => (
                          <tr key={key}>
                            <td className="br-r-8">
                              {(currentPage - 1) * perPage + key + 1}
                            </td>
                            <td
                              padding="none"
                              size={"small"}
                              key={item?.name}
                              component="td"
                            >
                              {item?.type}
                            </td>
                            <td size={"small"} align="center">
                              <p
                                variant="button"
                                color="white"
                                fontWeight="medium"
                              >
                                {item?.status || item?.transactionStatus ? (
                                  item?.status || item?.transactionStatus
                                  
                                ) : (
                                  "---"
                                )}
                              </p>
                            </td>
                            <td
                              padding="none"
                              size={"small"}
                              align="center"
                              component="td"
                            >
                              {item?.amount
                                ? `$ ${formatNumber(item?.amount)}`
                                : "---"}
                            </td>
                            <td
                              padding="none"
                              size={"small"}
                              align="center"
                              component="td"
                            >
                              {item?.bet ? `$ ${formatNumber(item?.bet)}` : "---"}
                            </td>
                            <td
                              padding="none"
                              size={"small"}
                              align="center"
                              component="td"
                            >
                              {item?.win ? `$ ${formatNumber(item?.win)}` : "---"}
                            </td>
                            <td
                              padding="none"
                              size={"small"}
                              align="center"
                              component="td"
                            >
                              {item?.jackpot
                                ? `$ ${formatNumber(item?.jackpot)}`
                                : "---"}
                            </td>
                            <td
                              padding="none"
                              size={"small"}
                              align="center"
                              component="td"
                            >
                              {item?.walletState
                                ? `$ ${formatNumber(
                                    item?.walletState.after.purchasedWallet +
                                      item?.walletState.after.stackingWallet +
                                      item?.walletState.after.winWallet
                                  )}`
                                : "---"}
                            </td>
                            <td
                              padding="none"
                              size={"small"}
                              align="center"
                              component="td"
                            >
                              {item?.rewardType ? ` ${item?.rewardType}` : "---"}
                            </td>
                            <td
                              padding="none"
                              size={"small"}
                              align="center"
                              component="td"
                            >
                              {item?._id || "---"}
                              {/* {item.type === 'AUTOMATIC PURCHASE' || item.type === 'MANUAL PURCHASE' ? item?._id : item?.transactionId || item?.orderId ? ` ${item?.transactionId || item?.orderId}` : "---"} */}
                            </td>

                            <td size={"small"} align="center">
                              <TimestampComponent
                                isoTimestamp={item?.createdAt}
                              />
                            </td>
                          </tr>
                        ))}
                      </>
                    ) : (
                      <>
                        <h1 className="clr-white" style={{ fontSize: "28px", color: "white" }}>
                          No Data Found
                        </h1>
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default LedgerUserTable;
