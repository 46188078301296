import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";

import authReducer from "../store/auth/authState";
import authUser from "../store/auth/user";
import loginUi from "../store/ui/loginUi";
import userData from "../store/ui/userData";
import addReferrel from "./ui/referrelLink";
import eidtUserSlice from '../store/auth/eidtProfile'
import userCashout from "../store/ui/cashout.slice";
import userCashoutHistory from "./ui/cashoutHistory.slice"
const combineReducer = combineReducers({
  auth: authReducer,
  user: authUser,
  login: loginUi,
  userData: userData,
  editUser:eidtUserSlice,
  addReferrel:addReferrel,
  userCashout: userCashout,
  cashoutHistory: userCashoutHistory,
});

const store = configureStore({
  reducer: combineReducer,
});

export default store;
