import { React, useState, useEffect } from "react";
import download from "../../../../assets/HomeAssets/assets/images/icon/download.png";
import search from "../../../../assets/HomeAssets/assets/images/icon/search.png";
import expand from "../../../../assets/HomeAssets/assets/images/icon/expand.png";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../../../store/ui/cashoutHistory.slice";
import { getUserCashoutHistory } from "../../../../store/api/cashout";
import { SpinnerDotted } from "spinners-react";
import "../../../../assets/HomeAssets/assets/css/cashout.css";
const isSet = {};
const PayoutHistory = () => {
  // new code starts here
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");
  const {
    hasMore,
    loading,
    cashoutHistory = [],
    perPage,
    perPageLimit,
    getAllUserBillHis = [],
  } = useSelector((state) => state.cashoutHistory);

  // useEffect(() => {
  //   // dispatch(getAllUserBills({}));
  // }, [dispatch]);

  useEffect(() => {
    if (isSet[perPage]) return;

    dispatch(getUserCashoutHistory({ perPage, perPageLimit }));
    isSet[perPage] = true;
  }, [dispatch, perPage, perPageLimit]);
  const searchHandler = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
  };
  const filteredBillHistory = getAllUserBillHis?.filter(
    (item) =>
      item.amount.toString().includes(searchTerm) ||
      item.orderId.includes(searchTerm)
  );
  const handleLoadMore = () => {
    dispatch(setLoading(true));
  };
  function formatDate(dateString) {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, options);
  }

  // new code ends here
  const [isInputVisible, setInputVisible] = useState(false);

  const handleSearchClick = () => {
    setInputVisible(!isInputVisible);
  };
  return (
    <>
      <div >
        <h1 className="f24 mb-0 text-white mt-64 ">Payout History</h1>
        <div className="table_width2 PayoutHistory-table mt-32">
          <div className="table_width2">
            <div className="custom_table table-wrapper">
              <table className="table giro-table table-bordered">
                <thead>
                  <tr>
                    <th className="br-r-8 ">
                      <Link className="Invoice_link">
                        {/* <img src={Invoice} alt="Invoice" /> */}
                        Invoice
                      </Link>
                    </th>
                    <th className="">Amount</th>
                    <th className="">Status</th>
                    {/* <th className=""> Payment method</th> */}
                    <th className=" ">Payment date</th>
                    <th className=" ">Transaction ID</th>
                    <th className="bl-r-8 text-end">
                      {/* <div className="search_wrap billing_search">
                          <div className="form-group icon_wrap">
                            <input
                              type="text"
                              onChange={searchHandler}
                              className="form-control"
                              placeholder="Search"
                            />
                            <div className="icon">
                              <img
                                src={billingsearch}
                                alt="search"
                                className="search"
                              />
                            </div>
                          </div>
                        </div> */}
                    </th>
                  </tr>
                </thead>
                <tbody className="scroll_bar  ">
                  {searchTerm ? (
                    <>
                      {filteredBillHistory?.map((item, key) => (
                        <tr key={key}>
                          <td className="br-r-8">
                            <div className="redio_wrap">
                              <label htmlFor={`checkbox-${key}`}>
                                #{item?._id?.slice(-6)}
                              </label>
                            </div>
                          </td>
                          <td className="">${item?.amount}</td>
                          <td className="">${item?.transactionStatus}</td>
                          {/* <td className="">
                              {item?.nuvei?.ccCardNumber ? (
                                <>
                                  <br />
                                </>
                              ) : (
                                ""
                              )}

                              {`${item?.nuvei?.cardType} / ${
                                item?.nuvei?.cardBrand
                                  ? item?.nuvei?.cardBrand
                                  : ""
                              }`}
                            </td> */}
                          <td className="">{formatDate(item?.createdAt)}</td>
                          <td>{item?.transactionId}</td>
                          <td className=""></td>
                        </tr>
                      ))}
                    </>
                  ) : cashoutHistory?.length > 0 ? (
                    <>
                      {cashoutHistory?.map((item, key) => (
                        <tr key={key}>
                          <td className="br-r-8">
                            <div className="redio_wrap">
                              <label htmlFor={`checkbox-${key}`}>
                                #{item?._id?.slice(-6)}
                              </label>
                            </div>
                          </td>
                          <td className="">${item?.amount}</td>
                          <td className="">${item?.transactionStatus}</td>
                          {/* <td className="">
                              
                              
                              {item?.nuvei?.cardType && item?.nuvei?.cardType}
                            </td> */}
                          <td className="">{formatDate(item?.createdAt)}</td>
                          <td>{item?.transactionId}</td>
                          <td className=""></td>
                        </tr>
                      ))}
                    </>
                  ) : (
                    <>
                      <h1 className="clr-white" style={{ fontSize: "28px", color: 'white' }}>
                        No Cashout History
                      </h1>
                    </>
                  )}
                </tbody>
              </table>
              {searchTerm?.length > 0 ? (
                <></>
              ) : (
                <>
                  {hasMore && (
                    <button
                      disabled={loading ? true : false}
                      onClick={handleLoadMore}
                      className="load_more "
                    >
                      {loading ? (
                        <SpinnerDotted style={{ height: "30", color: "fff" }} />
                      ) : (
                        "Load more invoices"
                      )}
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
          {/* <div className="custom_table    table-wrapper">
          <table className="table giro-table table-bordered">
            <thead>
              <tr>
                <th className="br-r-8 w-153">
                  <Link className="Invoice_link"> 
                    <img src={download} alt="download" /> Identifier
                  </Link>
                </th> 
                <th className="w-18 pl-0 ">Payment date</th>
                <th className="pl-0">Amount</th>
                <th className="pl-custom"> Status</th>
                <th className="pl-custom w-352 ">
                    <div className='search_box d-flex justify-content-end'>
                    <div className='search_inner'>
                    <Link className='icon-table search_btn' onClick={handleSearchClick}>
                        <img src={search} alt='search' />
                    </Link>
                    {isInputVisible && (
                        <div className='input_wrap_search'>
                        <div className='form-group'>
                            <input type='text' className='search-input' />
                            <Link className='' onClick={handleSearchClick}>
                            <img src={search} alt='search' />
                            </Link>
                        </div>
                        </div>
                    )}
                    </div>
                        <Link className='icon-table'><img src={expand} alt='expand'/></Link>
                    </div>
                </th>
              </tr>
            </thead>
            <tbody className="scroll_bar ">
              <tr>
                <td className="br-r-8 w-153">
                  <div className="redio_wrap">
                    <input type="radio" id="one" name="gender" />
                    <label htmlFor="one" className='txt-blue'><u>PAY-8752</u></label>
                  </div>
                </td>
                <td className="w-19">June 15, 2023</td>  
                <td className="pl-0 text-center">$100.00</td>
                <td className="pl-0 text-center">Completed</td> 
                <td className=" "></td> 
              </tr>
              <tr>
                <td className="br-r-8 w-153">
                  <div className="redio_wrap">
                    <input type="radio" id="two" name="gender" />
                    <label htmlFor="two" className='txt-blue'><u>PAY-8751</u></label>
                  </div>
                </td>
                <td className="w-19">June 15, 2023</td> 
                <td className="text-center">$30.00</td> 
                <td className="pl-0 text-center">Completed</td>
                <td className="pl-0 text-center"></td>
              </tr>
            </tbody>
          </table>
          <button type='button' className='btn_load_more'>Load more invoices</button>
        </div> */}
        </div>
      </div>
    </>
  );
};

export default PayoutHistory;
