import { React, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { SpinnerDotted } from "spinners-react";
import { formatNumber } from "../../../../services/number.services";

const ReferralProfitTable = ({loading, usersData, currentPage, perPage}) => {


  return (
    <>
      <div>
        {/* <h1 className="f24 mb-0 text-white mt-64 ">Payout History</h1> */}

        <div className="table_width3 PayoutHistory-table mt-32">
          <div className="table_width3">
            {loading ? (
              <div className="table-loader">
                <SpinnerDotted style={{ height: "30", color: "white" }} />
              </div>
            ) : (
              <div className="custom_table table-wrapper">
                <table className="table giro-table table-bordered">
                  <thead>
                    <tr>
                      <th className="align-center br-r-8">#</th>
                      <th className="align-center">Username</th>
                      <th className="align-center">Bet</th>
                      <th className="align-center">Win</th>
                      <th className="align-center">Jackpot</th>
                      <th className="align-center">Purchase</th>
                      <th className="align-center">Cashout</th>
                      <th className="align-center">Partner Profit</th>
                    </tr>
                  </thead>
                  <tbody className="scroll_bar  ">
                    {usersData?.length > 0 ? (
                      <>
                        {usersData?.map((item, key) => (
                          <tr key={key}>
                            <td className="br-r-8">
                              {(currentPage - 1) * perPage + key + 1}
                            </td>
                            <td className="align-center">{item?.user?.username}</td>
                            <td className="align-center">$ {formatNumber(item?.totalBetAmount)}</td>
                            <td className="align-center">$ {formatNumber(item?.totalWinAmount)}</td>
                            <td className="align-center">$ {formatNumber(item?.totalJackpotAmount)}</td>
                            <td className="align-center">$ {formatNumber(item?.cashin)}</td>
                            <td className="align-center">$ {formatNumber(item?.cashout)}</td>
                            <td className="align-center">$ {formatNumber(item?.totalPartnerAmount)}</td>
                          </tr>
                        ))}
                      </>
                    ) : (
                      <>
                        <h1 className="clr-white" style={{ fontSize: "28px", color: 'white' }}>
                          No Profits available
                        </h1>
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ReferralProfitTable;
