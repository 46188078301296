import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store/Store";
import "./index.css";
import "./assets/HomeAssets/assets/css/style.css";
import "./assets/HomeAssets/assets/css/responsive.css";

import ProtectedRoutes, { AuthGuard } from "./router/Protected";
import Redirect from "./router/Redirect";
import LoginPage from "../src/pages/Login/LoginPage";
import ForgotPassword from "./pages/Login/ForgotPassword";
import ResetEmail from "./pages/Login/ResetEmail";
import NewPassword from "./pages/Login/NewPassword";
import Dashboard from "./pages/Dashboard/Dashboard";
import Settings from "./pages/Dashboard/Settings/Settings";
import Gethelp from "./pages/Dashboard/Gethelp/Gethelp";
import Billing from "./pages/Dashboard/Billing/Billing";
import Cashouts from "./pages/Dashboard/Cashouts/Cashouts";

import Onboarding from "./pages/Onboarding/Onboarding";
import PasswordReset from "./pages/Login/PasswordReset.js";
import UnApprovedUsers from "./pages/Dashboard/UnApprovedUsers/UnApprovedUsers.js";
import storage from "./services/storage.js";
import ProfitsParent from "./pages/Dashboard/ProfitsParent/ProfitsParent.js";
import AffilateUsers from "./pages/Dashboard/AffiliateUsers/AffiliateUsers.js";
import LedgerUser from "./pages/Dashboard/Ledger/LedgerUser.js";
import JKDigitalsTransaction from "./pages/Dashboard/JKDigitalsTransaction/JKDigitalsTransaction.js";
import CashoutHistoryAffiliates from "./pages/Dashboard/CashoutHistoryAffiliates/CashoutHistoryAffiliates.js";
import ProfitsReferral from "./pages/Dashboard/ProfitsReferral/ProfitsReferral.js";

function App() {
  const user = storage.get("user");
  return (
    <Provider store={store}>
      <Router>
        <Routes>
          <Route
            exact
            path="/"
            element={
              <Redirect>
                <AuthGuard>
                  <LoginPage />
                </AuthGuard>
              </Redirect>
            }
          />
          <Route
            path="/forgot-password"
            element={
              <Redirect>
                <AuthGuard>
                  <ForgotPassword />
                </AuthGuard>
              </Redirect>
            }
          />
          <Route
            path="/resend-email"
            element={
              <Redirect>
                <AuthGuard>
                  <ResetEmail />
                </AuthGuard>
              </Redirect>
            }
          />
          <Route
            path="/reset-password"
            element={
              <Redirect>
                <AuthGuard>
                  <NewPassword />
                </AuthGuard>
              </Redirect>
            }
          />
         
          <Route
          path="/affiliate-profits"
          element={
            <ProtectedRoutes>
              <AuthGuard>
                <ProfitsParent />
              </AuthGuard>
            </ProtectedRoutes>
          }
        />
         <Route
          path="/affiliate-users/ledger/:userName/:userId"
          element={
            <ProtectedRoutes>
              <AuthGuard>
                <LedgerUser />
              </AuthGuard>
            </ProtectedRoutes>
          }
        />
          <Route
            path="/dashboard"
            element={
              <ProtectedRoutes>
                <AuthGuard>
                  <Dashboard />
                </AuthGuard>
              </ProtectedRoutes>
            }
          />
          <Route
            path="/settings"
            element={
              <ProtectedRoutes>
                <AuthGuard>
                  <Settings />
                </AuthGuard>
              </ProtectedRoutes>
            }
          />
          <Route
            path="/gethelp"
            element={
              <ProtectedRoutes>
                <AuthGuard>
                  <Gethelp />
                </AuthGuard>
              </ProtectedRoutes>
            }
          />
          <Route
            path="/billing"
            element={
              <ProtectedRoutes>
                <AuthGuard>
                  <Billing />
                </AuthGuard>
              </ProtectedRoutes>
            }
          />
          {/* Child Partner Routes */}
            <Route
          path="/un-approved-users"
          element={
            <ProtectedRoutes>
              <AuthGuard>
                <UnApprovedUsers />
              </AuthGuard>
            </ProtectedRoutes>
          }
        />
         <Route
          path="/affiliate-users"
          element={
            <ProtectedRoutes>
              <AuthGuard>
                <AffilateUsers />
              </AuthGuard>
            </ProtectedRoutes>
          }
        />
         <Route
          path="/purchase-history"
          element={
            <ProtectedRoutes>
              <AuthGuard>
                <JKDigitalsTransaction />
              </AuthGuard>
            </ProtectedRoutes>
          }
        />
         <Route
          path="/referral-cashouts"
          element={
            <ProtectedRoutes>
              <AuthGuard>
                <CashoutHistoryAffiliates />
              </AuthGuard>
            </ProtectedRoutes>
          }
        />
         <Route
          path="/referral-profits"
          element={
            <ProtectedRoutes>
              <AuthGuard>
                <ProfitsReferral />
              </AuthGuard>
            </ProtectedRoutes>
          }
        />
          {/* Parent Partner Routes */}
        
          
          <Route
            path="/on-boarding"
            element={
              <ProtectedRoutes>
                <Redirect>
                  <Onboarding />
                </Redirect>
              </ProtectedRoutes>
            }
          />
          <Route
            path="/password-reset"
            element={
              <Redirect>
                <AuthGuard>
                  <PasswordReset />
                </AuthGuard>
              </Redirect>
            }
          />
          <Route path="*" element={<Navigate to="/dashboard" />} />
        </Routes>
      </Router>
    </Provider>
  );
}
export default App;
