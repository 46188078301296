import { createSlice } from "@reduxjs/toolkit";

const userCashout = createSlice({
  name: "userCashout",
  initialState: {
    // virtualCards: [],
    userSavedCardsACH: [],
    userSavedCards: [],
    whiteListedStates: [],
    coin: "",
    redeem: false,
    threeDFingerprinting: {},
    threeDSecureUrl: {},
  },
  reducers: {
    setWhiteListedStates: (state, action) => {
      state.whiteListedStates = action.payload || [];
    },
    // setVirtualCard: (state, action) => {
    //   state.virtualCards = action.payload || []; // Update the value in the store
    // },
    // addVirtualCard: (state, action) => {
    //   if (action.payload) state.virtualCards.push(action.payload);
    // },
    // deleteVirtualCard: (state, action) => {
    //   const id = action.payload;
    //   const deleteUser = state.virtualCards.filter((item) => item.id !== id);
    //   state.virtualCards = deleteUser;
    // },
    getCoinRate: (state, action) => {
      state.coin = action.payload;
    },

    setRedeemToCashoutTab: (state, action) => {
      state.redeem = action.payload;
    },
    getUsersCard: (state, action) => {
      state.userSavedCards = action.payload;
    },
    addUsersCard: (state, action) => {
      const card = action.payload;
      if (!card) return;
      const { userPaymentOptionId } = card;

      state.userSavedCards = state.userSavedCards.filter(
        (el) => el.userPaymentOptionId !== userPaymentOptionId
      );

      state.userSavedCards.unshift(action.payload);
    },
    deleteUserCard: (state, action) => {
      const id = action.payload;
      const deleteUser = state.userSavedCards.filter(
        (item) => item.userPaymentOptionId !== id
      );
      state.userSavedCards = deleteUser;
    },
    // ach cards
    getUsersCardAch: (state, action) => {
      state.userSavedCardsACH = action.payload;
    },
    addUsersCardAch: (state, action) => {
      const card = action.payload;
      if (!card) return;
      const { userPaymentOptionId } = card;

      state.userSavedCardsACH = state.userSavedCardsACH.filter(
        (el) => el.userPaymentOptionId !== userPaymentOptionId
      );

      state.userSavedCardsACH.unshift(action.payload);
    },
    deleteUserCardAch: (state, action) => {
      const id = action.payload;
      const deleteUser = state.userSavedCardsACH.filter(
        (item) => item.userPaymentOptionId !== id
      );
      state.userSavedCardsACH = deleteUser;
    },
    setThreeDFingerprintingData: (state, action) => {
      const data = action.payload;
      state.threeDFingerprinting = data;
    },
    setThreeDSecureUrl: (state, action) => {
      const data = action.payload;
      state.threeDSecureUrl = data;
    },
  },
});

export const {
  getUsersCardAch,
  addUsersCardAch,
  setWhiteListedStates,
  // setVirtualCard,
  deleteUserCardAch,
  // addVirtualCard,
  getUsersCard,
  deleteUserCard,
  // deleteVirtualCard,
  addUsersCard,
  getCoinRate,
  setRedeemToCashoutTab,
  setThreeDFingerprintingData,
  setThreeDSecureUrl,
} = userCashout.actions;
export default userCashout.reducer;
